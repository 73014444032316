/*------------------------------------*
    #TEAM LIST
 *------------------------------------*/
.team-list {
    padding-top: 130px;
    padding-bottom: 10px;
}

.team-list--vertical {
    padding-top: 85px;
    padding-bottom: 50px;
}

.team-list__info {
    text-align: center;
    margin-bottom: 90px;
}

.team-list__name {
    @include font-size(16px, 1.2);
    margin-bottom: 10px;
}

.team-list__position {
    @include font-size(15px, 1.2);
    margin-bottom: 20px;
    color: #000;
}

.team-list__social-media {
    @include font-size(19px, 1.2);
    color: #AAA;
    margin-bottom: 20px;
    .team-list--vertical & {
        margin-bottom: 0;
    }
}

.team-list__body {
    padding-top: 20px;
    .team-list--vertical & {
        padding: 0;
    }
}
