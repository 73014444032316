/*------------------------------------*
    #IMAGE OVERLAYS
 *------------------------------------*/
.img-overlay {
    position: relative;
    @include image-overlay(0, $color-overlay-primary-background);
    @include image-overlay-hover(.95);
}

.img-overlay__rollover {
    @extend %absolute-centered;
    width: 100%;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    -webkit-transition: all .4s ease-in-out;

    color: $color-overlay-primary;

    .img-overlay:hover & {
        opacity: 1;
        visibility: visible;
    }

}

.img-overlay__icon {
    @include font-size(18px, 1.2);
}

.img-overlay__link {
    @extend .link-secret;
    @extend .link-silent;
}
