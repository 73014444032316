/*------------------------------------*
    #LINKS
 *------------------------------------*/

a {
    text-decoration: none;
    color: $color-links;
    @include attention() {
        color: $color-links-hover;
    }
}

//.link-quiet {
//    color: $color-gray-light;
//    text-decoration: underline;
//
//    @include attention() {
//        color: $color-links;
//    }
//
//}

.link-secret {
    color: inherit;
    @include font-weight(regular);

    @include attention() {
        color: $color-links-hover;
    }

}

.link-silent {
    @include attention() {
        color: inherit;
    }
}

.link-highlight {
    font-family: $font-secondary;
    color: $color-text-strong;
}

.link-strong {
    text-decoration: underline;
}
