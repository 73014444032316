/*------------------------------------*
    #HEADINGS
 *------------------------------------*/
.heading-page {
    @include font-size(60px, 48px);
    color: $color-brand;
    margin: 0;
    letter-spacing: -0.06em;
}

.heading-main {
}

.heading-sub {
}

.heading-banner {
}

.heading {
    @include font-size(35px, 1.2);
    margin-bottom: 28px;
}

.heading--small {
    @include font-size(22px, 1.2);
    margin-bottom: 27px;
}

.heading--large {
    @include font-size(36px, 1.4);
    margin-bottom: 15px;
}

.heading-sidebar {
    margin-bottom: 20px;
    color: $color-heading-sidebar;
}

.heading--tiny {
    @include font-size(13px, 1.2);
    margin-bottom: 10px;
}

.heading--large {

}

.heading-form {
    @include font-size(22px, 1.2);
    margin-bottom: 50px;
}

.heading-section-main {
    vertical-align: middle;
    position: relative;
    display: inline-block;
    @include font-size(14px, 1);
    font-family: $font-primary;
    color: $color-headings;
    text-transform: uppercase;
    letter-spacing: .025em;
    padding: 15.5px 32px;
    margin-bottom: 20px;
    overflow: hidden;
    &:before,
    &:after {
        height: 100%;
        width: 30%;
        position: absolute;
        content: '';
        -webkit-transition: all 0.35s ease;
        transition: all 0.35s ease;
        opacity: 1;
    }

    &:before {
        right: 0;
        top: 0;
        border-right: 2px solid $color-brand;
        border-top: 2px solid $color-brand;

    }
    &:after {
        left: 0;
        bottom: 0;
        border-left: 2px solid $color-brand;
        border-bottom: 2px solid $color-brand;
    }

    &:hover {

        &:before,
        &:after {
            height: 100%;
            width: 100%;
            position: absolute;
            content: '';
            -webkit-transition: all 0.35s ease;
            transition: all 0.35s ease;
            opacity: 1;
        }

    }

}

.heading-group {
    display: inline-block;
}

.heading-group__item {
    display: inline-block;
    overflow: visible;
    vertical-align: middle;
}

.heading-group__item + .heading-group__item {
    margin-left: 13px;
}

.heading-section-sub {
    letter-spacing: -.05em;
    line-height: 1;
}

.heading-blur {
    color: $color-heading-blur;
}

.heading-highlight {
    color: $color-heading-highlight;
}
