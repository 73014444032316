/*------------------------------------*
    #INTRO
 *------------------------------------*/
.intro__title {
    @include font-size(14px, 1.8);
    margin-bottom: 10px;
}

.intro__content {
    color: #777;
}

.intro__content p {
    @include font-size(15px, 1.8);
}
