/*------------------------------------*
    #TABLES
 *------------------------------------*/
.table-responsive {
    display: block;
    width: 100%;
    min-height: .01%;
    overflow-x: auto;
}

.table {
    max-width: 100%;
    margin-bottom: 10px;
}

.table--bordered {
    border: 2px solid $inuit-table-border-color;
}

.table--rows {
    border-top-width: 0px;
}


tr {
    .table--bordered  & {
        border-bottom: 1px solid $inuit-table-border-color;
    }
}

thead tr {

    .table--bordered & {
        border-bottom-width: 2px;
    }

}

th {

    .table--rows & {
        border-bottom-width: 2px;
    }

}

th,
td {
    padding: 10px;
    text-align: left;

    .table--center & {
        text-align: center;
    }

}

@include media-query(desk) {
    th,
    td {
        padding: 15px 0;
    }
}

.table__heading {
    font-family: $font-secondary;
    @include font-size(14px, 2.4);
    text-transform: uppercase;
    margin-bottom: 0;
}

.table__heading--small {
    @include font-size(13px, 2.4);
}
