/*------------------------------------*
    #PROGRESS
 *------------------------------------*/
.progress__bar {
    display: block;
    max-width: 100%;
    height: 5px;
    background: $color-progress-bar;
    position: relative;
}

.progress__bar-status {
    height: 100%;
    display: block;
    line-height: 1;
    position: relative;
    width: 0;
    min-width: 2em;
    background: $color-progress-bar-status;
    -webkit-transition: width .1s ease,background-color .1s ease;
    transition: width .1s ease,background-color .1s ease;
}

.progress__label {
    display: block;
    margin-bottom: 5px;
}

.progress__title {
    @include font-size(13px, 1.4);
    font-family: $font-secondary;
    color: $color-progress-title;
    margin-right: 25px;
}

.progress__percent {
    @include font-size(14px, 1.4);
    color: $color-progress-percent;
}
