/*------------------------------------*
    #PARAGRAPHS
 *------------------------------------*/

/**
 * The `.lede` class is used to make the introductory text (usually a paragraph)
 * of a document slightly larger: en.wikipedia.org/wiki/Lede_(news)
 */

.lede,
%lede {
    @include font-size(15px, 24px);
}

.description,
%description {
    @include font-size(15px, 1.8);
}
