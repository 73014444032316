/*------------------------------------*
    #Animations
 *------------------------------------*/
.animation-area__trigger {
    animation-play-state: paused;
}
.animation-area:hover .animation-area__trigger {
    animation-play-state: running;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes bubble {
    0%,100% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.98);
    }
}

@keyframes heartbeat {
    0%  { transform: scale(1.1); }
    7% { transform: scale(0.99); }
    14% { transform: scale(1.1); }
    21% { transform: scale(0.995); }
    28% { transform: scale(1.1); }
    60% { transform: scale(0.997); }
}

@keyframes slide-in {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@-webkit-keyframes flash {
    from, 50%, to {
        opacity: 1;
    }

    25%, 75% {
        opacity: 0;
    }
}

@keyframes flash {
    from, 50%, to {
        opacity: 1;
    }

    25%, 75% {
        opacity: 0;
    }
}




