/*------------------------------------*
    #STAR RATING
 *------------------------------------*/
.star-rating {
    font-family: FontAwesome;
    height: 1em;
    line-height: 1;
    overflow: hidden;
    position: relative;
    width: 65px;
    letter-spacing: 2px;
    font-size: 12px;
    top: 1px;
}

.star-rating--large {
    @include font-size(18px, 1.2);
    width: 92px;
}

.star-rating:before {
    color: #d3ced2;
    content: "\f005\f005\f005\f005\f005";
    float: left;
    left: 0;
    position: absolute;
    top: 0;
}

.star-rating span {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    padding-top: 1.5em;
    left: 0;
    overflow: hidden;
    padding-top: 1.5em;
    position: absolute;
    top: 0;
    color: #ffba00;
    letter-spacing: 2px;
}

.star-rating span:before {
    content: "\f005\f005\f005\f005\f005";
    left: 0;
    position: absolute;
    top: 0;
}
