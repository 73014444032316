// Font Awesome
$fa-angle-up: '\f106';
$fa-angle-down: '\f107';
$fa-angle-right: '\f105';
$fa-caret-down: '\f0d7';
$fa-caret-right: '\f0da';
$fa-long-arrow-right: '\f178';
$fa-search: '\f002';

// Pe-icon-7-stroke
$pe-angle-down: '\e688';
$pe-7s-search: '\e618';
