/*------------------------------------*
    #FORM
 *------------------------------------*/
 select {
     width: 100%;
     height: 2.4375rem;
     padding: 0.5rem;
     padding-right: 20px;
     border: none;
     margin: 0 0 1rem;
     font-size: 1rem;
     font-family: inherit;
     line-height: normal;
     color: #0a0a0a;
     background-color: #fff;
     border-radius: 0;
     -webkit-appearance: none;
     -moz-appearance: none;
     background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAGCAYAAAAVMmT4AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwMy8xMi8xNkgkiLIAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAP0lEQVQYlWNgYGAQYGBgeMDAwGDAgB0YQOUVYAILGBgYPmDRYAAVX4BuAroGnArRNSQQUois4T8xCmEgAZsgADtTD3CIR95EAAAAAElFTkSuQmCC');
     background-size: 11px 6px;
     background-position: right center;
     background-origin: content-box;
     background-repeat: no-repeat;
}

 .select--default {
     color: inherit;
     background-color: #f5f5f5;
     margin-bottom: 0;
     height: 42px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAECAYAAABCxiV9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDQvMTQvMTao6OngAAAAT0lEQVQImWXJMQGDMBAAwEsRwFwVRQJDBCABGS/h7XRjyIAEcMEYHHTp1N56JTMPvPx7P7Di/okTa4HMnLBj/MYcEX2A1tpVa93wxBIRHT58RxMzZedJFwAAAABJRU5ErkJggg==');
    background-size: 7px 4px;
 }


.input-group {
    display: table;
    width: 100%;
}

.input-group__child {
    margin: 0;
    display: table-cell;
    vertical-align: middle;
}

.input-group__child--field {
    border-radius: 0;
}

.input-group__child--button {
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;

        height: 100%;
        width: 1%;

}

// Specificity bump needed to prevent override by buttons
.input-group .input-group__child--button {
    display: table-cell;
}

 .text-input {
     background-color: #f5f5f5;
     border: 1px solid #f5f5f5;
     padding: 11px;
     width: 250px;
     max-width: 100%;
     -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
     box-sizing: border-box;
 }

 .text-input:focus,
 .text-input:active {
     border-color: #302f30;
     outline: none;
     background: white;
 }

 textarea {
     padding: 16px 30px 13px 15px;
     border: 1px solid #dddddd;
     background: white;
     outline: none;
     -webkit-appearance: none;
     width: auto;
     width: 250px;
 }

 .font-placeholder {
     &::placeholder {
         font-family: $font-tertiary;
     }
 }

 textarea.text-input {
     height: 150px;
 }

 textarea.text-input--large {
     height: 420px;
     width: 100%;
 }

 .text-input--full {
     width: 100%;
 }

 .text-input--full .ie7 {
     width: 95%;
 }

 .text-input.text-input--full-width {
     width: 100%;
 }

 .text-input.text-input--half-width {
     width: 50%;
 }

 .text-input.text-input--high {
     height: 400px;
 }

 .text-input--transparent {
     background: transparent;
     border: none;
     outline: none;
 }

 .field_with_errors input,
 .field_with_errors textarea {
     border: 1px #C84953 solid;
 }

 .form-list {
     @extend %list-bare;
     li + li{
         margin-top: 20px;
     }
 }

 .form-list--small {
     li + li {
         margin-top: 10px;
     }
 }

 .form-list--isolation-btn {
     li:last-child {
         margin-top: 35px;
     }
 }

 .label-small {
    @include font-size(12px, 1.2);
 }

 .check-ui {
     label {
         display: inline-block;
         color: inherit;
         font-family: $font-primary;
         @include font-size(15px, 1.4);
     }

 }
