/*------------------------------------*
    #SOCIAL MEDIA
 *------------------------------------*/

.social-media {
    .search-box & {
        padding: 31.5px 0;
    }
}

.social-media__title {
    @include font-size(13px);
    color: $color-base-text-color;
    font-family: $font-secondary;
}

.social-media__item + .social-media__item {
    margin-left: 30px;

    .social-media--small & {
        margin-left: 22px;
    }

    .social-media--tiny & {
        margin-left: 20px;
    }

}

    .social-media__link {
        display: block;
        color: $color-social-media-icon;

        .social-media--blur & {
            color: #CCC;
            @include attention() {
                color: $color-social-media-icon;
            }
        }

        .social-media--blur-darker & {
            color: #AAA;
            @include attention() {
                color: $color-social-media-icon;
            }
        }

    }
