///*------------------------------------*\
//    #MIXINS
//\*------------------------------------*/

// Spit out vendor prefixes in one go. Very pragmatic, far from perfect.
@mixin vendor($property, $value...) {
    -webkit-#{$property}: $value;
       -moz-#{$property}: $value;
            #{$property}: $value;
}

// Apply ‘attention’ styles in one go.
@mixin attention($self: false) {

    @if($self != false) {

        &,
        &:hover,
        &:active,
        &:focus {
            @content;
        }

    }

    @else {

        &:hover,
        &:active,
        &:focus {
            @content;
        }

    }

}

// Generate Helvetica Neue’s numbered font-weights from their aliases.
@mixin font-weight($font-weight) {

    @if ($font-weight == "regular") {
        font-weight: 300;
    }

    @if ($font-weight == "bold") {
        font-weight: 600;
    }

}

// Create all specified heading styles in one go.
@mixin headings($from: 1, $to: 6) {

    %base-heading {
        @content;
    }

    @if $from >= 1 and $to <= 6{

        @for $i from $from through $to{

            h#{$i} {
                @extend %base-heading;
            }

        }

    }

}

// Font awesome mixin
@mixin fa-icon($icon) {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    text-decoration: inherit;
    line-height: 1;
    content: $icon;
}

@mixin pe-icon($icon) {
    font-family: 'Pe-icon-7-stroke';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: $icon;
}

@mixin pe-icon-before($icon, $padding: 0) {
    &:before {
        @include pe-icon($icon);
        padding-right: $padding;
    }
}

@mixin fa-icon-before($icon, $padding: 0) {
    &:before {
        @include fa-icon($icon);
        padding-right: $padding;
    }
}

@mixin image-overlay($opacity, $color: black) {
    &:after {
        background: $color;
        content: "";
        height: 100%;
        left: 0;
        opacity: $opacity;
        position: absolute;
        top: 0;
        -webkit-transition: opacity .3s ease-in-out;
        transition: opacity .3s ease-in-out;
        width: 100%;
        z-index: 1;

    }
}

@mixin image-overlay-hover($opacity) {
    &:hover:after {
        opacity: $opacity;
    }
}

