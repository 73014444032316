/*------------------------------------*
    #FAQ LIST
 *------------------------------------*/
.faq-list {
    padding-top: 115px;
    padding-bottom: 60px;
}

    .faq-list__item {

        margin-bottom: 20px;

        @include media-query(lap-and-up) {
            margin-bottom: 0;
        }

    }

        .faq-list__icon {
            @include font-size(48px, 1.2);
            color: $color-icon-highlight;
            margin-bottom: 30px;
        }

        .faq-list__title {
            @include font-size(13px, 1.2);
            margin-bottom: 30px;
            color: $color-heading-highlight;
        }
