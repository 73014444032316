/*------------------------------------*
    #PHOTO GALLERY
 *------------------------------------*/
.photo-gallery {
    position: relative;

    .owl-prev, .owl-next {
        width: 42px;
        height: 42px;
        line-height: 38px;
        text-align: center;
        color: #fff;
        border: 2px solid #fff;
        position: absolute;
        top: 50%;
        margin-top: -21px;
    }

    .owl-prev {
        left: 15px;
    }

    .owl-next {
        right: 15px;
    }

}
