/*------------------------------------*
    #POSITIONED
 *------------------------------------*/
/**
 * Text alignment
 */

.text-right {
    text-align: right !important;
}

.desk-text-right {
    @include media-query(desk) {
        text-align: right !important;
    }
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center !important;
}
@include media-query(portable) {
.portable-text-center {

       text-align: center;
    }

}

.text-justified {
    text-align: justify;
}

.text-replace {
    color: transparent;
    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.text--white {
    color: white!important;
}

.text--strong {
    color: $color-text-strong;
}

.text--positive {
    color: $color-text-positive;
}

.text--negative {
    color: $color-text-negative;
}

@include media-query(palm) {
    .palm-text-right {
        text-align: right
    }
    .palm-text-left {
        text-align: left
    }
    .palm-text-center {
        text-align: center
    }
}

@include media-query(portable) {
    .portable-text-right {
        text-align: right
    }
    .portable-text-left {
        text-align: left
    }
    .portable-text-center {
        text-align: center
    }
}

@include media-query(lap-and-up) {
    .lap-and-up-text-right {
        text-align: right
    }
    .lap-and-up-text-left {
        text-align: left
    }
    .lap-and-up-text-center {
        text-align: center
    }
}





/**
 * Float
 */
.float--right {
    float: right;
}

.float--left {
    float: left;
}

@include media-query(portable) {
    .portable-float--right {
        float: right;
    }
    .portable-float--left {
        float: left;
    }
}

@include media-query(lap-and-up) {
    .lap-and-up-float--right {
        float: right;
    }
    .lap-and-up-float--left {
        float: left;
    }
}

@include media-query(desk) {
    .desk-float--right {
        float: right;
    }
    .desk-float--left {
        float: left;
    }
}

%absolute-centered {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

%absolute-centered-cover {
    height: auto;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: auto;
}

