/*------------------------------------*
    #POST TEASERS
 *------------------------------------*/
.post-teaser {
    text-align: center;
    margin-bottom: 70px;

    .teasers & {
        margin-bottom: 0;
    }
}

    .post-teaser--x-asis {
        text-align: left;
        margin-bottom: 55px;
    }

    .post-teaser__header {
        display: inline-block;
        position: relative;
        margin-bottom: 40px;
        @include media-query(desk) {
            @include image-overlay(0, #222);
            @include image-overlay-hover(0.5);
        }

        .post-teaser--x-asis & {
            margin-bottom: 0;
        }

    }

        .post-teaser__img {
            margin-bottom: 0;
        }

        .post-teaser__date {
            font-size: 13px;
            display: inline-block;
            background: $color-highlight-background;
            color: $color-highlight;
            padding: 5.5px 19px;

            .post-teaser__header & {
                margin-top: -15px;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 50%);
                -webkit-transform: translate(-50%, 50%);
                z-index: 2;
            }

            .post-teaser--x-asis & {
                margin-bottom: 24px;
            }

            .post-teaser--bare & {
                @include font-size(14px, 1.2);
                background: none;
                padding: 0;
                color: inherit;
                margin-bottom: 10px;
            }

        }

        .post-teaser__view-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -35.5px;
            margin-top: -35.5px;
            font-size: 26px;
            background: #fff;
            color:  $color-brand;
            width: 71px;
            height: 71px;
            line-height: 71px;
            border-radius: 50%;
            text-align: center;
            visibility: hidden;
            opacity: 0;
            -webkit-transition: opacity .7s ease-in-out;
            transition: opacity .7s ease-in-out;
            z-index: 2;

            @include media-query(desk) {

                .post-teaser__header:hover & {
                    visibility: visible;
                    opacity: 1;
                }

            }

        }


    .post-teaser__title {
        @include font-size(20px, 24px);
        margin-bottom: 20px;
        .post-teaser--x-asis & {
            margin-bottom: 15px;
        }
    }

    .post-teaser__content {

        @include font-size(14px, 1.6);
        margin-bottom: 13px;

        .post-teaser--x-asis & {
            margin-bottom: 24px;
        }

    }

        .post-teaser__content > :last-child{
           margin-bottom: 0;
        }
