/*------------------------------------*
    #ALIASES
 *------------------------------------*/

// Reassign `$inuit-base-spacing-unit` to `$spacing-unit`.
$spacing-unit:          $inuit-base-spacing-unit;
$spacing-unit--small:   $inuit-base-spacing-unit--small;
$spacing-unit--large:   $inuit-base-spacing-unit--large;

// Reassign lengthy font-size mixin to `font-size()`.
@mixin font-size($args...) {
    @include inuit-font-size($args...);
}
