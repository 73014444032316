/*------------------------------------*
    #QUOTES
 *------------------------------------*/

/**
 * Inline quotes.
 *
 */
q {
    font-style: italic;
    quotes: "‘" "’" "“" "”";

    &:before {
        content: open-quote;
    }

    &:after {
        content: close-quote;
    }

}





/**
 * Block quotes.
 *
 * 1. Hang opening punctuation.
 */
blockquote {
    margin-right: 2 * $spacing-unit;
    margin-left:  2 * $spacing-unit;
    font-style: italic;
}
