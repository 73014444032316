/*------------------------------------*
    #PAGE MICRO
 *------------------------------------*/
.page-micro {
    padding: 28px 0;
}

    .page-micro__list {
        font-family: $font-secondary;
        @include font-size(14px, 22px);

        @include media-query(palm) {
            margin-bottom: 10px;
        }

    }

        .page-micro__list-item {

            @include media-query(palm) {
                margin-right: 10px;
            }

        }

        .page-micro__list-item + .page-micro__list-item {

            @include media-query(lap-and-up) {
                margin-left: 50px;
            }

        }
