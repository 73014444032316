/*------------------------------------*
    #SITE NAV
 *------------------------------------*/
.main-menu-btn {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 28px;
    text-indent: 28px;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    vertical-align: bottom;
    margin: 0;
}
/* hamburger icon */
.main-menu-btn-icon, .main-menu-btn-icon:before, .main-menu-btn-icon:after {
    position: absolute;
    top: 50%;
    left: 2px;
    height: 2px;
    width: 24px;
    background: #bbb;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}
.main-menu-btn-icon:before {
    content: '';
    top: -7px;
    left: 0;
}
.main-menu-btn-icon:after {
    content: '';
    top: 7px;
    left: 0;
}
/* x icon */
#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon {
    height: 0;
    background: transparent;
}
#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before {
    top: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after {
    top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
/* hide menu state checkbox offscreen (so it stays visible to screen readers) */
#main-menu-state {
    position: absolute;
    top: -99999px;
}
/* hide the menu in mobile view */
#main-menu-state:not(:checked) ~ #js-site-nav {
    display: none;
}
#main-menu-state:checked ~ #js-site-nav {
    display: block;
}
@include media-query(lap-and-up) {
    /* hide the button in desktop view */
    .main-menu-btn {
        position: absolute;
        top: -99999px;
    }
    /* always show the menu in desktop view */
    #main-menu-state:not(:checked) ~ #js-site-nav {
        display: block;
    }
}





.site-nav__list {
    border-top: 2px solid $color-site-nav-border;
    border-bottom: 2px solid $color-site-nav-border;
    background: white;
}
    .site-nav__item {
        font-family: $font-secondary;

        text-transform: uppercase;
    }

    .site-nav__item + .site-nav__item {
        border-top: 1px solid rgba(141, 184, 99, 0.2);
    }

        .site-nav__link {
            @include attention(true) {
                padding: 13px 20px;
                /* make room for the toggle button (sub indicator) */
                padding-right: 58px;
                color: #333333;
                font-family: $font-secondary;
                font-size: 14px;
                font-weight: normal;
                line-height: 17px;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: .025em;
            }
        }

        .site-nav__link--current {
            font-weight: bold;
        }
        .site-nav__link.site-nav__link--disabled {
            color: #cccccc;
            cursor: not-allowed;
        }

            // Can not name it: site-nav__control, it depends the plugin.
            .site-nav .sub-arrow {
                position: absolute;
                top: 50%;
                margin-top: -17px;
                left: auto;
                right: 4px;
                width: 34px;
                height: 34px;
                overflow: hidden;
                font: bold 14px/34px monospace !important;
                text-align: center;
                text-shadow: none;
                background: rgba(141, 184, 99, 0.2);
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                -ms-border-radius: 4px;
                -o-border-radius: 4px;
                border-radius: 4px;
            }

            .site-nav .sub-arrow:before {
                @include fa-icon($fa-angle-down);
                display: block;
                line-height: inherit;
            }

        .site-nav__link.highlighted .sub-arrow:before {
            @include fa-icon($fa-angle-up);
            display: block;
            line-height: inherit;
        }

        // Dropdown Level 1
        .site-nav__dropdown.site-nav__dropdown {
            background: rgba(141, 184, 99, 0.2);
        }

        .site-nav__dropdown .site-nav__link {

            @include attention(true) {
                font-family: $font-primary;
                font-size: 15px;
                letter-spacing: normal;
                text-transform: none;
            }

            border-left: 8px solid transparent;
        }

            // Dropdown Level 2
            .site-nav__dropdown .site-nav__dropdown .site-nav__link {
                @include attention(true) {
                    border-left: 16px solid transparent;
                }
            }

                // Dropdown Level 3
                .site-nav__dropdown .site-nav__dropdown .site-nav__dropdown .site-nav__link {
                    @include attention(true) {
                        border-left: 24px solid transparent;
                    }
                }

                    // Dropdown Level 4
                    .site-nav__dropdown .site-nav__dropdown .site-nav__dropdown .site-nav__dropdown .site-nav__link {
                        @include attention(true) {
                            border-left: 32px solid transparent;
                        }
                    }

                        // Dropdown Level 5
                        .site-nav__dropdown .site-nav__dropdown .site-nav__dropdown .site-nav__dropdown .site-nav__dropdown .site-nav__link {
                            @include attention(true) {
                                border-left: 40px solid transparent;
                            }
                        }


        // Mega settings
        .site-nav__mega-content {
            @include attention(true) {
                font-family: $font-primary;
                font-size: 15px;
                letter-spacing: normal;
                text-transform: none;
            }
        }

        .site-nav__mega-content ul {
            display: block;
        }

        .site-nav__mega-dropdown.site-nav__mega-dropdown {
            padding: 13px 28px;
        }

        .site-nav__mega-col {
            margin-bottom: 24px;
        }

        .site-nav__mega-heading {
            margin-bottom: 20px;
        }

        .site-nav__mega-hr {
            margin-bottom: 0;
        }

        .site-nav__mega-list {

        }

        .site-nav__mega-list-item.site-nav__mega-list-item  {
            margin-bottom: 14px;
        }

        .site-nav__mega-list-icon {
            i {
                color: $color-site-nav-mega-icon;
                font-size: 12px;
                margin-right: 20px;
            }
        }

        .site-nav__mega-list-icon-item.site-nav__mega-list-icon-item  {
            margin-bottom: 15px;
        }

        .site-nav__mega-description {
            @include font-size(15px, 27px);
        }

        .site-nav__sub-link {
            color: #aaa;
        }





    // MAIN SEARCH
    .site-nav__main-search {

    }
    .site-nav__search-input.site-nav__search-input  {
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;
        margin: 10px 0;
        padding: 13px 20px;
    }
    .site-nav__search-close.site-nav__search-close {
        display: none;
    }






@include media-query(lap-and-up) {
    /* Switch to desktop layout
    -----------------------------------------------
    These transform the menu tree from
    collapsible to desktop (navbar + dropdowns)
    -----------------------------------------------*/
    /* start... (it's not recommended editing these rules) */
    .site-nav__item {
        float: left;

        .page-head--style-3 & {
            float: none;
            display: inline-block;
        }

        .sm-rtl & {
            float: right;
        }

        .site-nav__item & {

            float: none;
            .page-head--style-3 & {
                display: block;
            }

            .site-nav__list.sm-rtl &,
            .site-nav__list.sm-vertical & {
                float: none;
            }

        }

    }

        .site-nav__link {
            white-space: nowrap;

            .site-nav__dropdown &,
            .site-nav__list.sm-vertical & {
                white-space: normal;
            }

        }

        .site-nav__dropdown {
            position: absolute;
            width: 12em;
        }

    .sm-nowrap > li > a, .sm-mint .sm-nowrap > li > :not(ul) a {
        white-space: nowrap;
    }

    /* end... (it's not recommended editing above rules) */

    /**
     * STYLE DESKTOP MENU
     */

    .site-nav {
        border-top: 1px solid $color-site-nav-border--desktop;
        position: relative;

        .page-head--style-3 & {
            border-top: none;
            text-align: right;
        }

    }

    .site-nav__list {
        border: none;
        background: transparent;
    }

    // Nav item color
    .site-nav__link {

        @include attention(true) {
            padding: 27.5px 30px;
            color: $color-site-nav-item;
        }

        .page-head--style-2 & {
            padding: 31px 30px;
        }

    }

    // Nav item hover color
    .site-nav__link {
        @include attention(false) {
            color: $color-site-nav-dropdown-item--hover;
        }

    }

    // Link in dropdown item when access dropdown area of it
    .site-nav__link.highlighted {
        color: $color-site-nav-dropdown-item--highlight;
    }

    .site-nav__hover-icon {
        position: relative;
        @include attention(true) {
            color: #000;
            .page-head--style-3 & {
                color: #fff;
            }
        }

        &:after {
            content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAeCAYAAACiyHcXAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDMvMTQvMTZtT9duAAACMklEQVRIic2XzZ2bMBTE/0sDdgd2B6GDkCNcQgchFaxLIBWEDpZUEHKRrmwHuANvB6YC7+GhRQgR8MasMyf/BOKNZ6T38XC5XJhFEu+BCNgDIUqnJHEKZN36xrOrBWqgQOn6b59/mCSRxNsuyAHYdau/gArIga21PodnIEPpk+9hMEEgBBrgpxWoBXKUrjoi24UEAD4DDUmc+R6OlRAFTgwlPiKyh0DJcgV8+I7S5TQJIVADn6x3WuQspMCTtVYhajXAaSR1EkeInd/miLgkKuCrQyBCFHhCvC06S5ZBrK0YqiffVboB+0yIXz4C5+4ffUHp6CoCQBcoBP5YqxvEVsAo4T8HI+/+GUlcMrTnB0rnRomDQ+D55gQAlM4QSw0O0NtxcF4vbk6gR4ZYDbAhibOgy3y2Cu3Vvl8DuUX296MAOXw26tUI9CjnSDSrUxjWkl3AMDF9DAkHvtpx/h9IfDjuQ0JS+Z1JSDE0aO9FIrN+1wHDNApStteDFEq7olYBUrhsRCsS2CKtocELSpcB4wyZsgb6hslWIQc5mG6d2Ez1gjcgYCfGt0odoPQZ6aJt5N3GWxBIEcttAkcsxc3tyJ2tO4ZF5n3Bk7gGfuP2KtLavWXmvsdM4gJ4dD4ljCfmBU/gPXL9MsYdeYvMHqM2wSbh881sroByNElJ0BC5UZFnr9lfIA2yty4tafnfCxO8nFNyavjJGVuzFC+IcsVSG5fMohEi+dTUdUROfw3UZpa4Bq/g59EkB/Uf0AAAAABJRU5ErkJggg==');
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -16.5px;
            margin-left: -15px;
            opacity: 0;
            visibility: hidden;
            transition: all 1s;
            z-index: -1;
        }


        &:hover {

            &:after {
                opacity: 1;
                visibility: visible;

            }

        }

    }

    .site-nav__link.site-nav__link--disabled {
        @include attention(true) {
            background: transparent;
            color: #cccccc;
        }
    }

    .site-nav__link.has-submenu {
        padding-right: 34px;
        transition: all .3s;
    }

    .site-nav .sub-arrow {
        background: transparent;
    }

    .site-nav__link.highlighted .sub-arrow:before {
        @include fa-icon($fa-angle-down);
        display: block;
        line-height: inherit;
    }

    .site-nav__item + .site-nav__item {
        border-top: none;
    }

    // Use hack to overwrite sm ul by default of plugin
    .site-nav__dropdown.site-nav__dropdown {
        border: 0;
        background: #fff;
        padding: 14px 30px;
    }

    .site-nav__dropdown.site-nav__dropdown .site-nav__link.highlighted {
        padding-left: 30px;
    }

    .site-nav__dropdown .site-nav__item + .site-nav__item {
        border-top: 1px solid $color-site-nav-border--desktop;
    }

    /**
     * Link inside dropdown
     */
    .site-nav__dropdown .site-nav__link {
        border: 0;
        padding: 15px 0;
    }

        .site-nav__dropdown .sub-arrow {
            width: 23px;
            right: 0;
        }

        // Change apperance of arrow inside item of dropdown.
        .site-nav__dropdown .sub-arrow:before,
        .site-nav__dropdown .site-nav__link.highlighted .sub-arrow:before {
            @include fa-icon($fa-long-arrow-right);
            display: block;
            font-size: 11px;
            line-height: inherit;
        }



    // WHEN HOVER
    .site-nav__dropdown .site-nav__link {
        @include attention(false) {
            color: $color-site-nav-dropdown-item--hover;
        }
    }

    .site-nav__dropdown .site-nav__link.site-nav__link--disabled {
        background: transparent;
        color: #b3b3b3;
    }

    .site-nav__dropdown .site-nav__link.has-submenu {
        padding-right: 20px;
    }

    .site-nav__list .scroll-up,
    .site-nav__list .scroll-down {
        position: absolute;
        display: none;
        visibility: hidden;
        overflow: hidden;
        background: $color-site-nav-scroll-background;
        height: 20px;
    }

    .site-nav__list .scroll-up-arrow,
    .site-nav__list .scroll-down-arrow {
        position: absolute;
        top: 6px;
        left: 50%;
        margin-left: -8px;
        width: 0;
        height: 0;
        overflow: hidden;
        border-width: 0 6px 8px 6px;
        border-style: dashed dashed solid dashed;
        border-color: transparent transparent $color-site-nav-scroll transparent;
    }

    .site-nav__list .scroll-down-arrow {
        border-width: 8px 6px 0 6px;
        border-style: solid dashed dashed dashed;
        border-color: $color-site-nav-scroll transparent transparent transparent;
    }





    // Mega Settings
    .site-nav__mega-item.site-nav__mega-item {

    }

    .site-nav__mega-dropdown.site-nav__mega-dropdown {
        padding: 0;
    }

    .site-nav__mega-content {
        padding: 30px;
    }

    .site-nav__mega-hr {
        margin-bottom: 12px;
    }

    .site-nav__mega-list {

    }

    .site-nav__mega-list-item.site-nav__mega-list-item {
        margin-bottom: 24px;
    }






    // MAIN SEARCH
    .site-nav__search {
        position: absolute !important;
        z-index: 2;
        top: 0;
        left: 0;
        height: 100%;
        max-height: 73px;
        width: 100%;
        background: #fff;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity 0.3s, visibility 0.3s;
        -moz-transition: opacity 0.3s, visibility 0.3s;
        transition: opacity 0.3s, visibility 0.3s;

        .page-head--style-2 & {
            max-height: 79px;
        }
    }

    .site-nav__search-form {
        position: relative;
        height: 100%;
    }

    .site-nav__search-input.site-nav__search-input {
        background-color: transparent;
        color: #000;
        font-size: 2rem;
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        box-sizing: border-box;
        padding-right: 87px;
        margin: 0;
    }

    .site-nav__search-btn.site-nav__search-btn {
        overflow: hidden;
        padding: 21.5px 26.5px;
        border-left: 1px solid $color-border;
        border-right: 1px solid $color-border;


        .page-head--style-2 & {
            border-right: 0;
        }

    }

    .site-nav__search-btn.site-nav__search-close {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: auto;

        .page-head--style-2 & {
            border-right: 1px solid $color-border;
            height: 100%;
        }

    }

    .site-nav__search-icon {
        color: #000;
        @include font-size(22px, 26px);
        font-weight: bold;
    }

    .site-nav__search-block {
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;;
    }

    .site-nav__search-select {
        height: 72px;
        border-left: 1px solid $color-border;
        @include font-size(14px);
        color: #B1B1B1;

        .page-head--style-2 & {
            height: 79px;
        }
    }

    .site-nav__main-search--visible {
        /* search form open */
        opacity: 1;
        visibility: visible;
    }

    .site-nav__animate-search .site-nav__search--visible {
        /* trigger search form animation if <header> has the .animate-search class */
        animation: slide-in 0.3s;
    }

    .site-nav__search--visible {
        opacity: 1;
        visibility: visible;
    }

}

@include media-query(desk) {
    .site-nav__search-block {
        border: none;
    }

    .site-nav {
        .page-head--style-2 & {
            background: $color-base-background-color;
            border: none;
        }
    }
}



