/*------------------------------------*
    #LIST STACK
 *------------------------------------*/
.list-stack {
    @extend .list-bare;
}

.list-stack--bare {

}

.list-stack__item {
    @include font-size(15px, 21px);
    padding: 12px 0;
    .list-stack--bare & {
        padding: 0;
    }
}

.list-stack__item--darker {
    color: #666;
}

.list-stack__item + .list-stack__item {
    border-top: 1px solid $color-border;
    .list-stack--bare  & {
        border-top: none;
        margin-top: 26px;
    }

    .list-bare.list-stack--larger & {
        margin-top: 35px;
    }

}

.list-stack__link {
    font-family: $font-secondary;
    display: block;
    transition: margin 0.3s;
    .list-stack__item--active & {
        margin-left: 25px;
        color: $color-links-hover;
    }

}

.list-stack__counter {
    float: right;
    font-family: $font-tertiary;
    @include font-size(14px, 21px);
    color: inherit;
    font-weight: 300;
}


