/*------------------------------------*
    #SORTING BAR
 *------------------------------------*/
.sorting-bar {
    margin-bottom: 5px;
    font-family: $font-secondary;
    @include font-size(13px, 1.2);
}

.sorting-bar__setting {
    position: relative;
    display: inline-block;

    select {
        width: auto;
        color: inherit;
        background: none;
        margin: 0;
        padding: 0 20px;
        @include font-size(13px, 1.2);
    }

    &:after {
        @include fa-icon($fa-caret-down);
        @include font-size(12px, 1.2);
        color: $color-icon-highlight;
        position: absolute;
        top: 12px;
        right: 10px;
    }
}
