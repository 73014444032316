///*------------------------------------*\
//    #COLORS
//\*------------------------------------*/

$color-brand:       $brand-color;
$color-pink:        $color-brand;
$color-gray:        #333;
$color-gray-light:  #999;
$color-gray-ui:     #eee;
$color-green:       #859900;
$color-highlight:   #fff;
$color-tint:        #fbfbfb;
$color-tint-dark:   #f5f5f5;
$color-tint-darker: #f7f7f7;
$color-positive:    #D90000;
$color-negative:    #888888;





// Page
$inuit-base-background-color:   #fff;
$inuit-base-text-color:         $color-gray-light;

$color-base-background-color:   $inuit-base-background-color;
$color-base-text-color:         $inuit-base-text-color;

$color-highlight-background: $color-brand;

$color-disabled: $color-gray-ui;




// Heading
$color-heading-ui: #000;
$color-heading-blur: #ededed;
$color-headings:    #000;
$color-heading-sub: $color-gray;
$color-heading-highlight: $color-brand;
$color-heading-sidebar: $color-gray;




// Links
$color-links:           #aaa;
$color-links-hover:     $color-brand;
$color-links-carousel:  $color-gray-ui;





// Text
$color-code:            $color-green;
$color-pre-background:  $color-gray;
$color-pre:             #fff;
$color-text-strong:  $color-brand;
$color-text-positive:  $color-positive;
$color-text-negative:  $color-negative;




// Icon
$color-icon-highlight: $color-brand;





// Border
$color-border: $color-gray-ui;
$color-border-darker: #ddd;
$color-border-highlight: $color-brand;






// Buttons
$color-btn:                         #fff;
$color-btn-background:              $color-brand;
$color-btn-border:                  $color-brand;

$color-btn-secondary:               $color-gray;
$color-btn-secondary-background:    #fff;
$color-btn-secondary-border:        $color-gray-ui;

$color-btn-hover:               $color-btn-background;
$color-btn-background-hover:    $color-btn;

$color-btn-readmore-text:       $color-brand;
$color-btn-readmore-icon:       #aaa;
$color-btn-readmore-icon-hover: $color-brand;

$color-btn-util-text:       #000;
$color-btn-util-border:     #fff;
$color-btn-util-background: #fff;

$color-btn-transparent:         #fff;
$color-btn-transparent-border:  #fff;




// Iconic
$color-iconic:  $color-gray-ui;





// Page head
$color-masthead:                #fff;
$color-page-head-border:        $color-pink;
$color-page-head:               #fff;
$color-page-head-background:    #fff;




// Util nav
$color-util-nav:    $inuit-base-text-color;





// Site nav
$color-site-nav:                            $inuit-base-text-color;
$color-site-nav-border:                     $color-brand;
$color-site-nav-border--desktop:            $color-gray-ui;
$color-site-nav-item:                       $color-heading-ui;
$color-site-nav-dropdown-item--hover:       $color-brand;
$color-site-nav-dropdown-item--highlight:   $color-brand;
$color-site-nav-dropdown-item-background:   #fff;

$color-site-nav-scroll:             $color-brand;
$color-site-nav-scroll-background:  #fff;

$color-site-nav-mega-icon: $color-brand;




// Page foot
$color-page-foot:               #fff;





// Page micro
$color-page-micro:              #fff;
$color-page-micro-background:   $color-gray;





// Quotes
$color-quote:           #000;
$color-quote--banner:   $color-gray-light;





// Boxes
$color-box-highlight-background:    $color-highlight;
$color-box-highlight-border:        $color-gray-ui;
$color-box-tint-background:         $color-tint;
$color-box-tint-dark:                    #777;
$color-box-tint-background-dark:         $color-tint-dark;





// Bands
$color-band-highlight-background:   $color-highlight;
$color-band-tint-background:        $color-tint;
$color-band-tint-darker: #777;
$color-band-tint-darker-background: $color-tint-darker;
$color-band-dark:                   #868686;
$color-band-dark-background:        #000;





// Vertical Tab
$color-tab-item:        #000;
$color-tab-item-hover:  $color-brand;

// Mini Cart
$color-mini-cart-items-background:      #fff;
$color-mini-cart-icon: $color-brand;
$color-mini-cart-badge:                 #fff;
$color-mini-cart-badge-background:      #000;

// Product Thumbnail
$color-product-thumb-price: $color-brand;

// Featured Categories
$color-featured-categories-title:       #fff;
$color-featured-categories-title-hover: #000;
$color-featured-categories-overlay:     #fff;

// Newsletter
$color-newsletter-heading: #fff;

// Social Media
$color-social-media-icon: #000;

// Breadcrumb
$color-breadcrumb: #000;

// Overlay
$color-overlay-primary-background:     $color-brand;
$color-overlay-primary:                 #fff;
$color-overlay-secondary:               #fff;

// Progress
$color-progress-bar:        #eee;
$color-progress-bar-status: $color-brand;
$color-progress-title:      #1F1F1F;
$color-progress-percent:    $color-brand;
