/*------------------------------------*
    #BANDS
 *------------------------------------*/

.band {
    padding-top:    $spacing-unit;
    padding-bottom: $spacing-unit;

    @include media-query(desk) {
        padding-top:    120px;
        padding-bottom: 120px;
    }

}

.band--medium {
    @include media-query(desk) {
        padding-top:    110px;
        padding-bottom: 110px;
    }
}

.band--small {

    @include media-query(desk) {
        padding-top:    100px;
        padding-bottom: 100px;
    }

}

.band--uneven {

    @include media-query(desk) {
        padding-top: 150px;
        padding-bottom: 70px;
    }

}

.band--uneven-tiny {
    @include media-query(desk) {
        padding-top: 100px;
        padding-bottom: 60px;
    }
}

.band--uneven-small {
    @include media-query(desk) {
        padding-top: 130px;
        padding-bottom: 100px;
    }
}

.band--large {
    padding: $spacing-unit--large;
}

.band--highlight {
    background-color: $color-band-highlight-background;
}

.band--tint {
    background-color: $color-band-tint-background;
}

.band--tint-darker {
    background: $color-band-tint-darker-background;
}

.band--dark {
    background-color: $color-band-dark-background;
    color: $color-band-dark;
}
