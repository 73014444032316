/*------------------------------------*
    #BREADCRUMB
 *------------------------------------*/
.breadcrumb {
    @extend %list-inline;
    color: $color-breadcrumb;
    font-family: $font-secondary;
    @include font-size(14px, 17px);
}

.breadcrumb--small {
    @include font-size(13px, 1.2);
}

.breadcrumb--delimited {

    > li + li {
        &:before {
            @include fa-icon($fa-angle-right);
            margin: 0 21px;
        }

    }

}

.breadcrumb--delimited-caret {

    > li + li {
        &:before {
            @include fa-icon($fa-caret-right);
            color: #858585;
            margin: 0 21px;
        }

    }

}

.breadcrumb__item {
    @extend %list-inline__item;
}
