/*------------------------------------*
    #PULL QUOTE
 *------------------------------------*/
.pull-quote {
    margin: 0;
    margin-left:  25px;
    padding: 0 0 0 18px;
    border-left: 3px solid $color-border-highlight;
    font-style: normal;
    color: $color-quote;
}

.pull-quote--banner {
    padding: 0 20px;
    text-indent: 0;
    border-left: none;
    margin: 0 auto;
    color: $color-quote--banner;
    font-style: normal;
    @include media-query(lap-and-up) {
        @include font-size(22px, 33px);
    }
}
