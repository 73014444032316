/*------------------------------------*
    #PAGE HEAD
 *------------------------------------*/
.page-head {

    padding: 20px 0;

    @include media-query(desk) {
        padding-top: 35px;
        padding-bottom: 45px;
    }

}

.page-head--masthead {

    @include media-query(desk) {
        padding: 0;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;
    }

}

.page-head--style-2 {

    @include media-query(desk) {
        top: 20px;
    }

}

.page-head--style-3 {
    background: rgba(0, 0, 0, .2);
}

.page-head__logo {
    display: inline-block;

    @include media-query(desk) {
        display: block;
    }

    .page-head--style-2 & {
        padding: 22.5px 0;
        background: $color-base-background-color;
    }

    .page-head--style-3 & {
        padding: 29px 0;
    }
}

.page-head__util-nav {
    padding: 16.5px 0;
    @include media-query(desk) {
        padding: 26.5px 0;
    }

    .page-head--style-3 & {
        color: #000;
    }

    .util-nav__link {
        color: #000;
    }

}
