/*------------------------------------*
    #ABOUT LIST
 *------------------------------------*/
.about-list {
    text-align: center;
}
.about-list__content {
    position: relative;
    padding: 25px 0;
    margin-bottom: 13px;
}
.about-list__content:before,
.about-list__content:after {
    position: absolute;
    content: '';
    transition: all 0.35s ease;
    opacity: 1;

    .about-list__item:hover & {
        border-color: $color-border-highlight;
        width: 100%;
    }

}

.about-list__content:before {
    height: 100%;
    width: 70px;
    right: 0;
    top: 0;
    border-right: 2px solid $color-border-darker;
    border-top: 2px solid $color-border-darker;

    .about-list__item:last-child & {
        width: 100%;
    }

}

.about-list__content:after {
    height: 100%;
    width: 70px;
    left: 0;
    bottom: 0;
    border-left: 2px solid $color-border-darker;
    border-bottom: 2px solid $color-border-darker;

    .about-list__item:first-child & {
        width: 100%;
    }

}

.about-list__icon {
    color: $color-brand;
    @include font-size(40px, 48px);
}

.about-list__heading-main {
    font-family: $font-primary;
    @include font-size(18px, 33px);
    margin-bottom: 0;
    letter-spacing: .1em;

    .about-list__item:hover & {
        animation: flash 1.5s infinite;
        -webkit-animation: flash 1.5s infinite;
    }

}

