/*------------------------------------*
    #SEARCH BOX
 *------------------------------------*/
.search-box {
    border: 2px solid $color-border;
}

.search-box__form {
    position: relative;
    border-top: 2px solid $color-border;
    &:after {
        @include pe-icon($pe-7s-search);
        @include font-size(22px, 1.2);
        font-weight: bold;
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 50px;
        line-height: 50px;
        background: transparent;
        text-align: center;
        color: $color-text-strong;
        border-radius: 5px;
        -webkit-font-smoothing: subpixel-antialiased;
        font-smooth: always;
    }
}

.search-box__field {
    width: 100%;
    font-family: $font-tertiary;
    @include font-size(15px, 1.6);
    padding: 13px;
    padding-right: 70px;
}
.search-box__btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 50px;
    opacity: 0;
    cursor: pointer;
}
