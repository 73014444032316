/*------------------------------------*
    #PORTFOLIO DETAILS
 *------------------------------------*/
.portfolio-details {
    margin-bottom: 85px;
}

.portfolio-details__heading {
    @include font-size(36px, 50px);
    margin-bottom: 28px;
}

.portfolio-details__category {

}

.portfolio-details__description {
    @include font-size(14px, 25px);
    color: #777;
}

.portfolio-details__list {
    @extend %list-ui;
    @extend %list-ui--small;
    margin-bottom: 45px;
}

.portfolio-details__list-item {
    @extend %list-ui__item;

    .portfolio-details__list & {
        padding-left: 0;
    }

}

.portfolio-details__list-heading {
    color: $color-heading-highlight;
}

.portfolio-details__list-data {
    color: $color-heading-sub;
}

.portfolio-details__social-media {
    margin-bottom: 45px;
}

.portfolio-details__btn {
    padding: 11.5px 23px;
    @include font-size(13px, 1.2);
}
