/*------------------------------------*
    #BUTTONS
 *------------------------------------*/
.btn {

    font-family: $font-secondary;
    text-transform: uppercase;
    @include font-size(13px, 1.2);
    padding: 11.5px 24px;

    @include attention() {
        background: $color-btn-background-hover;
        color: $color-btn-hover;
        border-color: $color-btn-border;
    }

}


.btn--default {
    @include font-size(13px, 1.6);
    color: #888;
    background: #eee;
    border: 2px solid #eee;
    text-transform: none;
}

.btn--font-primary {
    @include font-size(13px, 15px);
    padding: 11.5px 24px;
}

.btn--secondary {
    background: $color-btn-secondary-background;
    color: $color-btn-secondary;
    border-color: $color-btn-secondary-border;
}

.btn--tertiary {
    background: #000;
    border-color: #000;
}

.btn--positive {
    @include font-weight(regular);

    @include attention(self) {
        background-color: $color-positive;
        border-color: $color-positive;
    }

}

//.btn--negative {
//    @include font-weight(regular);
//
//    @include attention(self) {
//        background-color: $color-negative;
//            border-color: $color-negative;
//    }
//
//}

.btn--transparent {
    background-color: transparent;
    color: $color-btn-transparent;
    border-color: $color-btn-transparent-border;
}

.btn--link {
    padding: 0;
    margin: 0;
    background: transparent;
    color: $color-links;
    border: none;
    @include font-size(14px, 21px);
    outline: none;
}


.btn--longer {
    padding: 11px 39px;
}

.btn--taller {
    padding-top: 12.5px;
    padding-bottom: 12.5px;
}


.btn-readmore {
    padding: 4px 0;
    @include font-size(15px, 18px);

    @include attention(true) {
        color: $color-btn-secondary;
        background: transparent;
        border-color: transparent;
    }
}

.btn-readmore__icon {
    display: inline-block;
    position: relative;
    transition: all .4s .1s;
    transform-origin: 50% 0%;
    transform-style: preserve-3d;

    .btn-readmore:hover & {
        transform: translate3d(0px, 0px, -30px) rotateX(90deg);
        -webkit-transform: translate3d(0px, 0px, -30px) rotateX(90deg);
    }

}

    .btn-readmore__icon-static {
        fill: $color-btn-readmore-icon;
    }

    .btn-readmore__icon-hover {
        fill: $color-btn-readmore-icon-hover;
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        top: 0;
        transform-origin: 50% 0;
        -webkit-transform-origin: 50% 0;
        transform: translate3d(0px, 105%, 0px) rotateX(-90deg);
        -webkit-transform: translate3d(0px, 105%, 0px) rotateX(-90deg);
    }

    .btn-readmore__text {
        margin-left: 15px;
        color: $color-btn-readmore-text;
    }
