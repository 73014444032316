/*------------------------------------*
    #PORTFOLIO
 *------------------------------------*/
.portfolio__post {
    margin-bottom: 75px;
}

.portfolio__post--small {
    margin-bottom: 60px;
}

.portfolio__icon {
    color: $color-overlay-primary;
    @include font-size(18px, 22px);
    margin-bottom: 10px;
}

.portfolio__title-main {
    @include font-size(22px, 31px);
    margin-bottom: 12px;

    .portfolio__rollover & {
        color: $color-overlay-primary;
    }

    .portfolio__post--small & {
        @include font-size(18px, 25px);
        margin-bottom: 5px;
    }

}

.portfolio__title-sub {
    @include font-size(14px, 1);
    font-family: $font-primary;
    color: $color-text-strong;
    margin: 0;

    .portfolio__rollover & {
        color: $color-overlay-primary;
    }

    .portfolio__post--small & {
        @include font-size(13px, 18px);
    }

}

.portfolio__btn-loadmore {
    padding: 17.5px 59px;
    @include font-size(14px, 17px);
}

.portfolio__img-wrapper {
    position: relative;
    @include image-overlay(0, $color-overlay-primary-background);
    @include image-overlay-hover(.97);
}

.portfolio__rollover {
    @extend %absolute-centered;
    width: 100%;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    -webkit-transition: all .4s ease-in-out;

    .portfolio__img-wrapper:hover & {
        opacity: 1;
        visibility: visible;
    }

}
