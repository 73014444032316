/*------------------------------------*
    #DRAWER
 *------------------------------------*/
.drawer {
    overflow: hidden;
}

    .drawer__content {

        margin-top: -150%;
        transition: margin-top 0.7s;
        -webkit-transition: margin-top 0.7s;

        @include media-query(lap-and-up) {
            margin-top: -100%;
        }

        .is-open & {
            margin-top: 0;
        }

        .no-js & {
            margin-top: 0;
        }

    }
