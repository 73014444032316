/*------------------------------------*
    #SLIDE
 *------------------------------------*/
.slide__title {
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
    margin-bottom: 50px;
}

.slide__title--small {
    margin-bottom: 30px;
    padding: 0;
}

.slide__title-no-border {
    border-bottom: none;
}

.slide__heading {
    display: inline-block;
    @include font-size(30px, 43px);
    margin-bottom: 0;
    color: #333;
}

.slide__controls {

    .slide__title--small & {
        line-height: 45px;
    }

}

.slide__btn {
    display: inline-block;
    width: 43px;
    height: 43px;
    line-height: 43px;
    border: 1px solid #eee;
    text-align: center;
    font-size: 15px;

    @include attention() {
        background: #eee;
    }

    .slide__title--small & {

        width: 37px;
        height: 37px;
        line-height: 37px;
        font-size: 17px;

    }

}

.slide__btn--secondary {
    color: #000;

    @include attention() {
        background: transparent;
        border-color: $color-border-highlight;
    }

}
