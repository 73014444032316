fieldset {
    border: none;
}

label {
    font-family: $font-secondary;
    @include font-size(13px, 1.2);
    display: block;
    margin-bottom: 10px;
    cursor: pointer;
    color: #000;
}

input,
textarea,
select {
    border: none;
    padding: 12px;
    vertical-align: middle;

    &::placeholder {
        font-weight: 300;
        opacity: 1 !important;
    }
}
