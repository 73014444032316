/* =========================================================================

    Project: Begge - Main CSS
    Author: Insight Studio

   ========================================================================= */

// ----------------------------------------------------------------------------

// Settings – used with preprocessors and contain font, colors definitions, etc.
// Tools – globally used mixins and functions. It’s important not to output any CSS in the first 2 layers.
// Generic – reset and/or normalize styles, box-sizing definition, etc. This is the first layer which generates actual CSS.
// Base – styling for bare HTML elements (like H1, A, etc.). These come with default styling from the browser so we can redefine them here.
// Objects – class-based selectors which define undecorated design patterns, for example media object known from OOCSS
// Components – specific UI components. This is where majority of our work takes place and our UI components are often composed of Objects and Components
// Trumps – utilities and helper classes with ability to override anything which goes before in the triangle, eg. hide helper class

///*----------------------------------------------------------*
//                    BREAKPOINT
//
//        ──palm─┤ ├──lap──┤ ├─desk────────────
//        ─portable────────┤        ├─desk-wide
//                         ├──lap-and-up───────────────
//
// *----------------------------------------------------------*/




/**
 * CONTENTS
 *
 * GENERIC
 * * Normalize
 * * Reset
 * * Box sizing
 * * Shared
 *
 * BASE
 * * Page
 * * Headings
 * * Paragraphs
 * * Type
 * * Links
 * * Forms
 * * Lists
 * * Images
 * * Quotes
 *
 * OBJECTS
 * * Progress
 * * Overlays
 * * Animations
 * * Drawer
 * * Buttons
 * * Layout
 * * Box
 * * Block
 * * Pack
 * * Media
 * * Flag
 * * List bare
 * * List inline
 * * Breadcrumb
 * * List block
 * * List UI
 * * Tables
 * * Tabs
 * * Drop down
 * * Images
 * * HR
 * * Pagination
 * * Accordion
 * * Owl carousel
 *
 * COMPONENTS
 * * Bands
 * * Headings
 * * Buttons
 * * Forms
 * * Quantity
 * * Star rating
 * * Page head
 * * Util Nav
 * * Sm core css
 * * Site nav
 * * Tabs
 * * Product thumbnails
 * * Product tabs
 * * Product Slide
 * * Mini cart
 * *  Featured products
 * * Featured categories
 * * About list
 * * Service list
 * * Post teaser
 * * Comment teaser
 * * Newsletter
 * * Brands
 * * Page foot
 * * Page micro
 * * Social media
 * * Billboard
 * * Portfolio
 * * Portfolio details
 * * Slide
 * * Pull quote
 * * Testimonials
 * * Intro
 * * Skills
 * * Work process
 * * Team list
 * * Img Overlay
 * * 404 banner
 * * Faq list
 * * Order guide
 * * Filter box
 * * Search box
 * * List stack
 * * Sorting bar
 * * Product details
 * * Filter bar
 * * Post
 * * Photo Gallery
 * * Banner
 *
 * TRUMPS
 * * Headings
 * * Clearfix
 * * Widths
 * * Widths Responsive
 * * Spacing
 * * Spacing Responsive
 * * Show-hide
 * * Positioned
 * * Print
 *
 */






// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
@import url("https://fonts.googleapis.com/css?family=Varela");
@import url("https://fonts.googleapis.com/css?family=Varela+Round");
@import url("https://fonts.googleapis.com/css?family=Muli:300");

// **
// * #SETTINGS
// *
$inuit-base-font-size:          15px;
$inuit-base-line-height:        23px;
$inuit-base-text-color:         #999;
@import "vendor/inuitcss/inuit-defaults/settings.defaults";

@import "1-settings.global";
@import "1-settings.colors";
@import "1-settings.icon-fonts";
@import "vendor/inuitcss/inuit-responsive-settings/settings.responsive";





// **
// * #TOOLS
// *
@import "vendor/inuitcss/inuit-functions/tools.functions";
@import "vendor/inuitcss/inuit-mixins/tools.mixins";
@import "2-tools.mixins";
@import "vendor/inuitcss/inuit-tools-widths/tools.widths";
@import "vendor/inuitcss/inuit-responsive-tools/tools.responsive";
@import "2-tools.aliases";





// **
// * #GENERIC
// *
@import "vendor/inuitcss/inuit-normalize/generic.normalize";
@import "vendor/inuitcss/inuit-reset/generic.reset";
@import "vendor/inuitcss/inuit-box-sizing/generic.box-sizing";
@import "vendor/inuitcss/inuit-shared/generic.shared";





// **
// * #BASE
// *
@import "vendor/inuitcss/inuit-page/base.page";
@import "3-base.page";

// Predefine the variables below in order to alter and enable specific features.
$inuit-heading-size-1:  40px;
$inuit-heading-size-2:  30px;
$inuit-heading-size-3:  18px;
$inuit-heading-size-4:  16px;
$inuit-heading-size-5:  15px;
$inuit-heading-size-6:  14px;
@import "vendor/inuitcss/inuit-headings/base.headings";

@import "3-base.paragraphs";
@import "3-base.type";
@import "3-base.links";
@import "3-base.forms";
@import "vendor/inuitcss/inuit-lists/base.lists";
@import "vendor/inuitcss/inuit-images/base.images";
@import "3-base.quotes";





// **
// * #OBJECTS
// *
@import "4-objects.progress";
@import "4-objects.overlays";
@import "4-objects.animations";
@import "4-objects.wrappers";
@import "4-objects.drawer";

$inuit-btn-background:      $color-btn-background;
$inuit-btn-border-width:    2px;
$inuit-enable-btn--small:   true;
$inuit-enable-btn--full:    true;
@import "vendor/inuitcss/inuit-buttons/objects.buttons";

$inuit-enable-layout--middle:   true;
$inuit-enable-layout--bottom:   true;
$inuit-enable-layout--large:    true;
$inuit-enable-layout--small:     true;
$inuit-layout-gutter--tiny:       10px;
$inuit-enable-layout--tiny:     true;
$inuit-enable-layout--flush:    true;
$inuit-enable-layout--auto:    true;
$inuit-enable-layout--rev:    true;
$inuit-enable-layout--center:    true;
$inuit-layout-gutter: 30px;
@import "vendor/inuitcss/inuit-layout/objects.layout";

$inuit-box-namespace:       $inuit-namespace !default;

$inuit-enable-box--small:         true;
$inuit-box-padding--small:          16.5px;
$inuit-enable-box--large:           true;
$inuit-box-padding--large:          50px;
$inuit-enable-box--huge:           true;
$inuit-box-padding--huge:          200px;
@import "vendor/inuitcss/inuit-box/objects.box";

@import "4-objects.box";

$inuit-block-gutter:            30px;
$inuit-block-gutter--small:     25px;
$inuit-enable-block--small:     true;
$inuit-enable-block--center:     true;
@import "vendor/inuitcss/inuit-block/objects.block";
@import "vendor/inuitcss/inuit-pack/objects.pack";

$inuit-enable-media--tiny:    true;
$inuit-media-gutter--tiny:    10px;
$inuit-enable-media--small:    true;
$inuit-media-gutter--small:    15px;
$inuit-enable-media--large:    true;
$inuit-media-gutter--large:    30px;
$inuit-enable-media--responsive: true;
$inuit-media-collapse-at: 700px;
@import "vendor/inuitcss/inuit-media/objects.media";

$inuit-enable-flag--rev:        true;
$inuit-enable-flag--responsive: true;
$inuit-flag-collapse-at:        479px;
@import "vendor/inuitcss/inuit-flag/objects.flag";

/**
 * Kinda hacky for now, but needs more testing before rolling back into main
 * flag object.
 */
.flag__img {
    white-space: nowrap;
}

@import "vendor/inuitcss/inuit-list-bare/objects.list-bare";

$inuit-enable-list-inline--delimited:   true;
$inuit-list-inline-delimit-character:   "|";
@import "vendor/inuitcss/inuit-list-inline/objects.list-inline";

.list-inline--delimited > li + li:before {
    margin: 0 15px;
}

@import "4-objects.breadcrumb";

$inuit-enable-list-block--small:     true;
$inuit-list-block-padding--small:       3px;
@import "vendor/inuitcss/inuit-list-block/objects.list-block";
.list-block--flush,
%list-block--flush {
    > .list-block__item,
    > %list-block__item {
        padding: 0;
    }
}

.list-block__title {
    display: block;
    font-family: $font-secondary;
    @include font-size(15px, 1.2);
    margin-bottom: 20px;
}

$inuit-list-ui-border-color:    $color-gray-ui;
$inuit-enable-list-ui--small:   true;
@import "vendor/inuitcss/inuit-list-ui/objects.list-ui";

/**
 * Quick, inline extension of the inuitcss list-ui object.
 */
.list-ui__title {
    display: block;
    @include font-size(12px);
    @include font-weight(bold);
    color: $color-gray-light;
    margin-bottom: 0;
}

$inuit-enable-table--rows:      true;
$inuit-enable-table--columns:   true;
$inuit-enable-table--fixed:     true;
$inuit-table-border-color:      #EFEFEF;
@import "vendor/inuitcss/inuit-tables/objects.tables";
@import "4-objects.tables";
@import "4-objects.tabs";
@import "4-objects.dropdown";
@import "4-objects.images";
.layout--flush img {
    @include media-query(desk) {
        width: 100%;
    }
}
@import "4-objects.hr";
@import "4-objects.pagination";
@import "4-objects.accordion";
@import "4-objects.owl-carousel";






/**
 * #COMPONENTS
 */
@import "5-components.bands.scss";
@import "5-components.headings";
@import "5-components.buttons";
@import "5-components.forms";
@import "5-components.quantity";
@import "5-components.star-rating";
@import "5-components.page-head";
@import "5-components.util-nav";
@import "vendor/smartmenus/dist/css/sm-core-css";
@import "5-components.site-nav";
@import "5-components.tabs";
@import "5-components.product-thumbnail";
@import "5-components.product-tabs";
@import "5-components.product-slide";
@import "5-components.mini-cart";
@import "5-components.featured-product";
@import "5-components.featured-categories";
@import "5-components.about-list";
@import "5-components.service-list";
@import "5-components.post-teaser";
@import "5-components.comment-teaser";
@import "5-components.newsletter";
@import "5-components.brands";
@import "5-components.page-foot";
@import "5-components.page-micro";
@import "5-components.social-media";
@import "5-components.billboard";
@import "5-components.portfolio";
@import "5-components.portfolio-details";
@import "5-components.slide";
@import "5-components.pull-quote";
@import "5-components.testimonials";
@import "5-components.intro";
@import "5-components.skills";
@import "5-components.work-process";
@import "5-components.team-list";
@import "5-components.img-overlay";
@import "5-components.404-banner";
@import "5-components.faq-list";
@import "5-components.order-guide";
@import "5-components.filter-box";
@import "5-components.search-box";
@import "5-components.list-stack";
@import "5-components.widget";
@import "5-components.sorting-bar";
@import "5-components.product-details";
@import "5-components.filter-bar";
@import "5-components.post";
@import "5-components.photo-gallery";
@import "5-components.banner";





/**
 * #TRUMPS
 */
@import "vendor/inuitcss/inuit-headings-trumps/trumps.headings";
@import "6-trumps.heading";
@import "vendor/inuitcss/inuit-clearfix/trumps.clearfix";

$inuit-use-fractions:   false;
$inuit-widths-columns: (
    1,2,3,4,5,6,7,8,9,10,11,12
);
@import "vendor/inuitcss/inuit-widths/trumps.widths";

$inuit-widths-columns-responsive: (
    1,2,3,4,5,6,7,8,9,10,11,12
);
@import "vendor/inuitcss/inuit-widths-responsive/trumps.widths-responsive";

$inuit-enable-paddings:          true;
$inuit-enable-margins--negative: true;
$inuit-enable-margins:          true;
$inuit-enable-margins--large:    true;
$inuit-margin--large: 50px;
$inuit-enable-margins--huge:    true;
$inuit-margin--huge: 135px;
$inuit-enable-margins--none:    true;
@import "vendor/inuitcss/inuit-spacing/trumps.spacing";


$inuit-enable-responsive-paddings:          true;
$inuit-enable-responsive-paddings--none:    true;
$inuit-enable-responsive-paddings--large:   true;
$inuit-enable-responsive-paddings--huge:   true;
$inuit-enable-responsive-margins:          true;
$inuit-enable-responsive-margins--none:    true;
$inuit-enable-responsive-margins--small:   true;
$inuit-enable-responsive-margins--large:   true;
$inuit-enable-responsive-margins--huge:   true;
@import "vendor/inuitcss/inuit-spacing-responsive/trumps.spacing-responsive";
@import "6-trumps.show-hide";
@import "6.trumps.positioned";
@import "vendor/inuitcss/inuit-print/trumps.print";
