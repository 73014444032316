/*------------------------------------*
    #404 BANNER
 *------------------------------------*/
.banner-404 {
    padding: 80px 0;
    text-align: center;
    background: $color-highlight-background;
    @include media-query(lap-and-up) {
        padding: 180px 0;
    }
}

.banner-404__title-main {
    display: inline-block;
    letter-spacing: normal;
    color: $color-highlight;
    padding: 25px 30px;

    @include media-query(lap-and-up) {
        border: 2px solid $color-highlight;
    }

}

.banner-404__title-sub {
    @include font-size(18px, 1.6);
    color: $color-highlight;
    margin: 0;
}

.banner-404__link {
    text-decoration: underline;
}
