/*------------------------------------*
    #HEADING
 *------------------------------------*/
/* These handle massive type, for less frequently occuring bits of text (e.g. in mastheads and banners). */
.giga {
    @include font-size(80px, 1.2);
}
.mega {
    @include font-size(64px, 1.125)
}
.kilo {
    @include font-size(60px, 1);
    margin-bottom: 19px;
}
