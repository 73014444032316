/*------------------------------------*
    #PRODUCT TABS
 *------------------------------------*/
.product-tabs {

}
    .product-tabs__controls {

        margin-bottom: 15px;

        @include media-query(lap-and-up) {
            margin-bottom: 65px;
        }

    }

    .product-tabs__heading {
        margin-bottom: 15px;

        @include media-query(lap-and-up) {
            margin-bottom: 0;
        }

    }

    .product-tabs__menu > li + li:before {
        content: '/';
        @include font-size(16px, 19px);
        margin: 0 15px;
    }
