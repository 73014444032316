///*------------------------------------*\
//    #GLOBAL
//\*------------------------------------*/

$brand-color:   #ff4948;
$brand-round:   3px;
$base-round:    $brand-round;

// Font definitions
$font-mont: 'Montserrat', sans-serif;
$font-varela: 'Varela Round', sans-serif;
$font-muli: 'Muli', sans-serif;

$font-primary: $font-varela;
$font-secondary: $font-mont;
$font-tertiary: $font-muli;


// Z-indexes
$zindex-base: 1 !default;
