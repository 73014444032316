/*------------------------------------*
    #FILTER BOX
 *------------------------------------*/
.filter-box {
    border: 2px solid $color-border-highlight;
    padding: 25px 15px;
}

.filter-box__heading {
    margin-bottom: 18px;
}

.filter-box__filter {
    @extend .progress;
    margin-bottom: 18px;
}

.filter-box__bar {
    @extend .progress__bar;
    &:hover {
        cursor: e-resize;
    }
}

.filter-box__bar-status {
    @extend .progress__bar-status;
}

.filter-box__bar-status:after {
    display: block;
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    right: -4.5px;
    top: -2.5px;
    background: #000;

}

.filter-box__label {
    @include font-size(13px, 1.4);
    line-height: 42px;
}

.filter-box__btn {
    color: $color-heading-sidebar;
}
