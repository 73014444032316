/*------------------------------------*
    #PRODUCT SLIDE
 *------------------------------------*/
.product-slide {
    background: $color-base-background-color;
    padding: 30px 17px;
}

    .product-slide__heading {
        display: inline-block;
        margin-bottom: 30px;
    }

    .product-slide__controls {
        display: inline-block;
        line-height: 45px;
    }

        .product-slide__btn {
            display: inline-block;
            width: 37px;
            height: 37px;
            line-height: 37px;
            text-align: center;
            border: 1px solid $color-border;
            color: $color-btn-util-text;

            @include attention() {
                border: 1px solid $color-links-hover;
            }

            &.disabled {
                color: $color-disabled;
                border-color: $color-disabled;
                cursor: not-allowed;
            }

        }

    .product-slide__product + .product-slide__product {
        margin-top: 30px;
    }

        .product-slide__img-link {
            position: relative;
            @include image-overlay(0, $color-brand);

            .product-slide__product:hover & {

                &:after {
                    opacity: .9;
                }

            }

        }

        .product-slide__img-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            z-index: 2;
            font-size: 24px;
            color: #fff;
            visibility: hidden;
            opacity: 0;

            .product-slide__product:hover & {
                visibility: visible;
                opacity: 1;
            }

        }
