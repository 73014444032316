/*------------------------------------*
    #MINI CART
 *------------------------------------*/
.mini-cart {
    display: block;
    position: relative;

    @include media-query(lap-and-up) {
        display: inline-block;

        .util-nav & {
            margin-left: 10px;
            vertical-align: top;
        }

    }

}

    .mini-cart__controls {
        text-align: center;
    }

    .mini-cart__btn-cart {
        position: relative;
        display: inline-block;
        line-height: 1;
        vertical-align: bottom;
    }

    .mini-cart__icon {
        @include font-size(25px, 1);
        color: $color-mini-cart-icon;
        font-weight: bold;


    }

        .mini-cart__badge {
            border-radius: 50%;
            background-color: $color-mini-cart-badge-background;
            color: $color-mini-cart-badge;
            display: inline-block;
            font-size: 11px;
            font-weight: bold;
            text-align: center;
            min-height: 15px;
            min-width: 15px;
            line-height: 15px;
            position: absolute;
            top: -7px;
            right: -7px;

            .page-head--style-3 & {
                color: $color-mini-cart-badge-background;
                background:  $color-mini-cart-badge;;
            }
        }

    .mini-cart__items {
        display: none;
        position: static;
        background: $color-mini-cart-items-background;
        padding: 23px 27px;
        border: 2px solid $color-border;

        @include media-query(lap-and-up) {
            position: absolute;
            left: auto;
            right: 0;
            width: 370px;
        }

    }

        .mini-cart__item.mini-cart__item {
            padding: 0;
        }

        .mini-cart__item.mini-cart__item + .mini-cart__item {
            padding-top: 14px;
            margin-top: 14px;
            border-top: 1px solid $color-border;
        }

        .mini-cart__item-info {
            position: relative;
            padding-right: 20px;
        }

        .mini-cart__qty {
            margin-left: 10px;
            @include font-size(14px, 1);
        }

        .mini-cart__action {
            position: absolute;
            top: 0;
            right: 0;
        }

        .mini-cart__action-btn {
            @include font-size(18px, 1);
        }

        .mini-cart__btn {
            padding: 8.5px 30px;
        }

            .mini-cart__btn + .mini-cart__btn {
                margin-left: 5px;
            }
