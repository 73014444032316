/*------------------------------------*
    #TYPE
 *------------------------------------*/

/**
 * Typographical base selectors.
 */

/**
 * Headings 1–6.
 */
@include headings() {
    font-family: $font-mont;
    @include font-weight(regular);
    color: $color-headings;
}





/**
 * A generic heading style which can be applied to any headings.
 */
.heading {
    @include font-size(22px);
}

.heading-sub {
    @include font-size(16px);
    @include font-weight(bold);
    margin-bottom: 0;
    color: $color-heading-sub;
}





/**
 * Emboldened elements.
 */
//a,
//strong, b,
//dt {
//    @include font-weight(bold);
//}





/**
 * Code-like elements.
 */
code, kbd, samp {
    color: $color-code;
    font-family: Inconsolata, Monaco, Consolas, "Andale Mono", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
    font-style: normal;
}

pre {
    white-space: pre;
    word-wrap: normal;
    overflow: auto;
    padding: $spacing-unit--small;
    background-color: $color-pre-background;
    border-radius: $base-round;

    &,
    code, kbd, samp {
        color: $color-pre;
    }

}





/**
 * Horizontal rules.
 */
hr {
    border: none;
    border-bottom: 1px solid $color-gray-ui;
    margin-bottom: $spacing-unit - 1px;
}





/**
 * Highlighted text.
 */
mark {

    pre & {
        background: none;
        border-bottom: 1px solid;
        color: inherit;
    }

}





/**
 * Text-alignment.
 */
.text-banner {
    text-align: center;
}
