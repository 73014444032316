/*------------------------------------*
    #PRODUCT THUMBNAIL
 *------------------------------------*/
.product-thumb {

}

    .product-thumb__title {
        @include font-size(16px, 19px);
        margin-bottom: 10px;
    }

    .product-thumb__img {
        display: inline-block;
        position: relative;
        overflow: hidden;

        @include media-query(desk) {
            @include image-overlay(0);
        }

    }

    // Overlay Image.
    .product-thumb__img:after {

        .product-thumb:hover & {
            opacity: .7;
        }

    }

    .product-thumb__actions {
        margin-bottom: 10px;
        @extend %clearfix;

        @include media-query(desk) {
            margin: 0;
        }

    }

    .product-thumb__btn {
        float: left;
        width: 33%;
        height: 42px;
        padding: 0;

        @include media-query(desk) {
            width: auto;
            float: none;
            position: absolute;
            padding: 0 14px;
            z-index: 2;
            opacity: 0;
            visibility: hidden;
            -webkit-transform: translateY(100%);
            transform: translateY(100%);

            .product-thumb:hover & {
                opacity: 1;
                visibility: visible;
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }
        }

    }

    .product-thumb__btn + .product-thumb__btn {
        padding: 0;
        display: block;

        @include media-query(desk) {
            width: 42px;
            color: $color-btn-util-text;
            background: $color-btn-util-background;
            border-color: $color-btn-util-border;
        }

    }

        .product-thumb__btn-text {
            margin-left: 8px;
        }

        .product-thumb__btn i {
            @include font-size(14px);
        }

    .product-thumb__btn:nth-child(1) {
        bottom: 7px;
        left: 7px;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }

    .product-thumb__btn:nth-child(2) {
        bottom: 51px;
        left: 7px;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .product-thumb__btn:nth-child(3) {
        bottom: 95px;
        left: 7px;
        -webkit-transition: all 0.6s ease;
        transition: all 0.6s ease;
    }

    .product-thumb__price {
        color: $color-product-thumb-price;
        @include font-size(18px, 1);
    }

    .product-thumb__category {
        position: absolute;
        z-index: 2;
        top: 5px;
        left: 8px;
        @include font-size(13px);

        @include media-query(desk) {
            top: 11px;
            left: 14px;
            @include font-size(14px);
            visibility: hidden;
            opacity: 0;
            -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
            -webkit-transition: all 0.4s ease;
            transition: all 0.4s ease;

            .product-thumb:hover & {
                visibility: visible;
                opacity: 1;
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-transition: all 0.4s ease;
                transition: all 0.4s ease;
            }

        }

    }

    .product-thumb__sale-badge {
        color: $color-brand;
        font-family: $font-secondary;
        position: absolute;
        z-index: 2;
        top: 5px;
        right: 8px;

        @include font-size(13px);

        @include media-query(desk) {
            top: 16px;
            right: 12px;

            @include font-size(14px);
            letter-spacing: .025em;
            color: $color-headings;

            animation: heartbeat 2.5s infinite;
            animation-play-state: paused;

            .product-thumb:hover & {
                color: #fff;
                -webkit-transform: translateY(0);
                transform: translateY(0);
                animation-play-state: running;
            }

        }

    }

    .product-thumb__sale-badge:after {

        @include media-query(desk) {

            content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAeCAYAAACiyHcXAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDMvMTQvMTZtT9duAAACMklEQVRIic2XzZ2bMBTE/0sDdgd2B6GDkCNcQgchFaxLIBWEDpZUEHKRrmwHuANvB6YC7+GhRQgR8MasMyf/BOKNZ6T38XC5XJhFEu+BCNgDIUqnJHEKZN36xrOrBWqgQOn6b59/mCSRxNsuyAHYdau/gArIga21PodnIEPpk+9hMEEgBBrgpxWoBXKUrjoi24UEAD4DDUmc+R6OlRAFTgwlPiKyh0DJcgV8+I7S5TQJIVADn6x3WuQspMCTtVYhajXAaSR1EkeInd/miLgkKuCrQyBCFHhCvC06S5ZBrK0YqiffVboB+0yIXz4C5+4ffUHp6CoCQBcoBP5YqxvEVsAo4T8HI+/+GUlcMrTnB0rnRomDQ+D55gQAlM4QSw0O0NtxcF4vbk6gR4ZYDbAhibOgy3y2Cu3Vvl8DuUX296MAOXw26tUI9CjnSDSrUxjWkl3AMDF9DAkHvtpx/h9IfDjuQ0JS+Z1JSDE0aO9FIrN+1wHDNApStteDFEq7olYBUrhsRCsS2CKtocELSpcB4wyZsgb6hslWIQc5mG6d2Ez1gjcgYCfGt0odoPQZ6aJt5N3GWxBIEcttAkcsxc3tyJ2tO4ZF5n3Bk7gGfuP2KtLavWXmvsdM4gJ4dD4ljCfmBU/gPXL9MsYdeYvMHqM2wSbh881sroByNElJ0BC5UZFnr9lfIA2yty4tafnfCxO8nFNyavjJGVuzFC+IcsVSG5fMohEi+dTUdUROfw3UZpa4Bq/g59EkB/Uf0AAAAABJRU5ErkJggg==");
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -16.5px;
            margin-left: -16px;
            transition: all 1s;
            z-index: -1;
        }

    }

    .product-thumb__star-rating {
        display: block;
        margin-top: 30px;
    }


