/*------------------------------------*
    #FEATURED PRODUCT
 *------------------------------------*/
.featured-product {
    display: inline-block;
    position: relative;
}

    .featured-product__info {
        margin: 15px 0;
        border: 2px solid $color-border-highlight;
        padding: 10px;
        z-index: $zindex-base;

        @include media-query(lap-and-up) {
            padding: 53px 40px;
        }

        @include media-query(desk) {
            margin: 0;
        }

    }

    .featured-product__info--left {
        padding: 40px 70px;
    }

        .featured-product__title {
            @include font-size(25px, 1);
            line-height: 1;
            letter-spacing: -.05em;
            margin-bottom: 17px;

            .featured-product__info--negative & {
                color: #fff;
            }

        }

        .featured-product__price {
            @include font-size(20px);
        }

        .featured-product__price-old {
            @include font-size(15px, 1);
        }

        .featured-product__btn {
            font-family: $font-primary;
            margin-top: 25px;
            padding: 14px 23.5px;
            @include font-size(14px, 17px);
        }

    .featured-product__info--negative {
        text-align: center;
        background: #000;
        border-color: #000;
    }

    .featured-product__category {
        display: inline-block;
        font-family: $font-primary;
        letter-spacing: .025em;
    }

        .featured-product__main-category {
            @include font-size(15px, 1);
            margin: 0;
        }

        .featured-product__sub-category {
            @include font-size(54px, 1);
            margin-left: 15px;
            margin: 0;
        }

// LAP AND UP
@include media-query(desk) {

    .featured-product__info--right {
        max-width: 274px;
        width: 100%;
        position: absolute;
        top: 28px;
        left:  calc((100% - 23px));
    }

    .featured-product__info--left {
        max-width: 452px;
        width: 100%;
        position: absolute;
        bottom: 50px;
        right:  calc((100% - 23px));
    }

}

