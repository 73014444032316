/*------------------------------------*
    #FILTER BAR
 *------------------------------------*/
.filter-bar {
    margin-bottom: 15px;
    > * {
        vertical-align: middle;
    }
}

    .filter-bar__controls {

        @include font-size(13px, 1.2);
        font-family: $font-secondary;

        @include media-query(lap-and-up) {
            text-align: right;
        }

    }

    .filter-bar__controls--active {
        color: $color-links-hover;
    }

    .filter-bar__controls li {
        padding: 13.5px 20px;
        background: #f5f5f5;
    }

    .filter-bar__controls i {
        margin-right: 10px;
    }
