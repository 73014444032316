/*------------------------------------*
    #BANNERS
 *------------------------------------*/

.banner--highlight {
    position: relative;
    z-index: 2;
    @include media-query(desk) {
        margin-top: -85px;
    }
}
