/*------------------------------------*
    #BRANDS
 *------------------------------------*/
.brands {
    padding: 50px 0;
}

img.brands__img.brands__img  {
    width: auto;
}

.brands__link  {
    display: inline-block;
}

.brands__item {
    text-align: center;
}

.brands .owl-item {
    float: none;
    display: inline-block;
    vertical-align: middle;
}
