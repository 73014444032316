/*------------------------------------*
    #UTIL NAV
 *------------------------------------*/
.util-nav {

}

.util-nav--highlight {
    background: #000;
}

    .util-nav__list {
        display: inline-block;
        vertical-align: top;
    }

        .util-nav__item {
            color: #A5A5A5;
        }

            .util-nav__link {
                @include font-size(14px, 25px);
                color: $color-util-nav;

                @include media-query(desk) {
                    text-transform: uppercase;
                }

                .util-nav--highlight & {
                    color: #fff;
                }

            }

    .util-nav__list--delimited {

        > li + li {

            &:before {
                margin: 0 15px;
            }

        }
    }
