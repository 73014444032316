/*------------------------------------*
    #TESTIMONIALS
 *------------------------------------*/
.testimonials {
    padding-top: 90px;
    padding-bottom: 35px;
}

.testimonials--small {
    padding-left: 120px;
    padding-right: 120px;
}

.testimonials--small-desk {
    padding: 10px;

    @include media-query(desk) {
        @extend testimonials--small;
    }
}

.testimonials__heading-main {
    margin-bottom: 15px;
}

.testimonials__heading-sub {
    line-height: 1;
    margin-bottom: 80px;

    .testimonials--small & {
        margin-bottom: 50px;
    }

    .testimonials--small-desk & {
        margin-bottom: 10px;
    }

    @include media-query(desk) {
        .testimonials--small-desk & {
            margin-bottom: 50px;
        }
    }
}

img.testimonials__avatar.testimonials__avatar {
    max-width: 100%;
    width: auto;
    display: inline-block;
    margin-bottom: 50px;
}

.testimonials__quote {
    margin-bottom: 35px;
    .testimonials--small & {
        @include font-size(20px, 1.5);
    }
}

.testimonials__author {
    display: inline-block;
    margin-bottom: 90px;
}

.testimonials__author-name {
    display: block;
    color: $color-text-strong;
    font-style: normal;
    font-family: $font-secondary;
    @include font-size(20px, 1);
    margin-bottom: 12px;
}

.testimonials__author-info {
    display: block;
}

.owl-theme .owl-dots .owl-dot span,
.owl-theme .owl-dots .owl-dot span {
    .testimonials & {
        width: 9px;
        height: 9px;
        margin: 0px 3px;
        background: $color-quote--banner;
        transition: all .4s;
        border: 2px solid transparent;
    }
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    .testimonials & {
        background: transparent;
        border: 1px solid $color-quote--banner;
        transform: scale(1.4);
        -webkit-transform: scale(1.4);
    }
}


