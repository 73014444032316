/*------------------------------------*
    #TABS
 *------------------------------------*/

.tabs__menu,
%tabs__menu {
    margin:  0; /* [1] */
    padding: 0; /* [1] */
    list-style: none; /* [1] */

    .tabs--vertical & {
        margin-bottom: 20px;
        @include media-query(lap-and-up) {
            padding: 30px;
        }
    }
}

.tabs__menu--edge,
%tabs__menu--edge {
    border: 2px solid $color-border;
}

.tabs__menu-item,
%tabs__menu-item {
    display: inline-block; /* [2] */

    .tabs--vertical & {

        @include media-query(lap-and-up) {

            @include font-size(15px, 21px);
            text-transform: capitalize;
            display: block;
            padding: 10px 0;

        }
    }

}

.tabs__menu-item + .tabs__menu-item {

    @include media-query(lap-and-up) {
        .tabs--vertical & {
            border-top: 1px solid $color-border;
        }
    }

}

.tabs__menu-link,
%tabs__menu-link {
    display: inline-block;
    text-transform: uppercase;
    font-family: $font-secondary;
    letter-spacing: .05em;

    .tabs__menu-item.active &,
    &.is-checked {
        color: $color-links-hover;
    }

    @include media-query(lap-and-up) {

        .tabs--vertical & {
            letter-spacing: normal;
            text-transform: capitalize;
            transition: all .4s;
            -webkit-transition: all .4s;
        }

        .tabs__menu-item.active &,
        &.is-checked {
            .tabs--vertical & {
                padding-left: 20px;
            }
        }

    }

}

.tabs__content {

    .tabs--vertical & {
        position: relative;
    }

}

.tabs__menu > li + li:before {
    content: '/';
    @include font-size(16px, 19px);
    margin: 0 15px;

    @include media-query(lap-and-up) {
        .tabs--vertical & {
            display: none;
        }
    }

}

.tabs__content-title {
    @include font-size(15px, 1.8);
    margin-bottom: 1em;
}

.tabs__content-text {
    margin-bottom: 80px;
}
