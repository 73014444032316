/*------------------------------------*
    #BILLBOARD
 *------------------------------------*/
.billboard {

    background: #EEEFF1;
    padding: 30px 0;

    @include media-query(lap-and-up){
        height: 300px;
        background: #EEEFF1 url('../images/billboard/1920x300/billboard-bg.jpg') center no-repeat;
    }

}

.billboard--parallax {
    background: none;
}

    .billboard__content {
        display: table;
        width: 100%;
        height: 100%;
    }

    .billboard__col {

        margin-bottom: 10px;

        @include media-query(lap-and-up){

            display: table-cell;
            vertical-align: middle;
            width: 45%;
            margin: 0;
        }

    }
