/*------------------------------------*
    #SERVICE LIST
 *------------------------------------*/
.service-list__item {
    margin-bottom: 35px;
}

.service-list__icon {
    @include font-size(48px, 1.2);
    color: $color-heading-highlight;
}

.service-list__title {
    @include font-size(13px, 1.2);
    color: $color-heading-highlight;
    margin-bottom: 15px;
}
