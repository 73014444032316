/*-------------------------------------------------*
    #Box - Extending objects.box of framework.
 *-------------------------------------------------*/
.box--uneven {
    padding: 20px 30px;
}

.box--tint-dark {
    color: $color-box-tint-dark;
    background: $color-box-tint-background-dark;
}

.box--edge {
    border: 2px solid $color-border;
}

.box--triangle {

    position: relative;
    margin-left: 7px;

    &:before {
        content:'';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 7px 6px 0;

        position: absolute;
        left: -7px;
        top: 50%;
        margin-top: -6px;

    }

    &.box--tint-dark:before {
        border-color: transparent $color-band-tint-darker-background transparent transparent;
    }

}

@media screen and (min-width: 70em) {

    .box--huge-desk {
        padding: $inuit-box-padding--huge;
    }

}





