/*------------------------------------*
    #POST
 *------------------------------------*/
.post__img,
.post__video,
.post__audio {
    margin-bottom: 40px;
}

.post__heading {
    @include font-size(27px, 1.2);
    margin-bottom: 30px;
}

.post__meta {
    @include font-size(13px, 22px);
    font-family: $font-secondary;

    span {
        margin-right: 5px;
    }

    margin-bottom: 25px;

}

.post__icon {
    @include font-size(20px, 22px);
    color: $color-icon-highlight;
    vertical-align: middle;
    margin-right: 5px;
}

.post__entry {
    margin-bottom: 30px;
}

// Styling for the blog post content
.cms-content {

    h2,
    h3,
    h4,
    h5 {
        margin-bottom: 0.5 * $spacing-unit;
    }

    h2 {
        font-size: 1.55rem;
    }

    h3 {
        font-size: 1.1rem;
    }

    h4 {
        font-size: 0.9rem;
    }

    h5 {
        font-size: 0.8rem;
    }

    blockquote {
        p {
            padding-left: $spacing-unit;
            position: relative;

            &:before {
                font-size: 2rem;
                font-weight: bold;
                left: 0;
                line-height: 1;
                position: absolute;
                top: 0.25 * $spacing-unit;
            }

            &:after {
                content: "";
            }
        }
    }
}

.post-author {
    padding: 60px 0;
}

.post-author__avatar {
    margin-bottom: 18px;
}

.post-author__name {
    margin-bottom: 3px;
}

.post-author__job {
    letter-spacing: .05em;
}

.post-author__content {
    position: relative;
    margin-left: 7px;
}

.post-author__content:before {
    content:'';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 7px 6px 0;
    border-color: transparent $color-band-tint-darker-background transparent transparent;
    position: absolute;
    left: -7px;
    top: 50%;
    margin-top: -6px;
}

.post-comment {

}


.post-comment__children {
    padding-top: 30px;
    border-top: 1px solid $color-border;

    margin-left: 20px;
    @include media-query(lap-and-up) {
        margin-left: 65px;
    }

}

.post-comment__title {
    @include font-size(22px, 1.2);
    padding-bottom: 15px;
    border-bottom: 1px solid $color-border;
}

.post-comment__comments {
    margin-bottom: 50px;
}

.post-comment__thecomment {

    .post-comment__children & {
        margin-bottom: 20px;
    }

}

.post-comment__content {
    padding-bottom: 20px;

    .post-comment__children & {
        padding-bottom: 0;
        border: none;
    }

}

.post-comment__text {
    margin-bottom: 5px;
}

.post-comment__author {
    margin: 0;
    margin-left: 7px;
}

.post-comment__date {
    @include font-size(15px);
    font-family: $font-tertiary;
    margin-right: 20px;
    i {
        font-size: 20px;
        vertical-align: middle;
        margin-right: 5px;
    }
}

.post-comment__reply {
    @include font-size(14px);
}

.post-comment__leave-title {
    @include font-size(22px);
    margin-bottom: 35px;
}
