/*------------------------------------*
    #HR
 *------------------------------------*/
.hr {
    margin-bottom: 30px;
}

.hr-large {
    border-bottom-width: 2px;
}

.hr-away {
    margin-bottom: 40px;
}
