/*------------------------------------*
    #ORDER GUIDE
 *------------------------------------*/
.order-guide {
    padding-top: 60px;
    padding-bottom: 70px;
}

.order-guide__content {
    @include font-size(14px, 1.8);
}

.order-guide__title {
    margin: 0;
    padding-bottom: 15px;

    .order-guide__list-item & {
        padding-bottom: 25px;
    }

        .order-guide__list-item + .order-guide__list-item & {
            padding-top: 30px;
        }

}

.order-guide__item {
    margin-bottom: 25px;
    &:last-child {
        margin-bottom: 0;
    }
}

.order-guide__icon {
    @include font-size(12px, 1.2);
    color: #878787;
}

.order-guide__icon--open,
.is-open .order-guide__icon--close {
    display: none;
}

.order-guide__icon--open {

    .is-open & {
        display: block;
    }

}

.order-guide__list-item {
    border-bottom: 1px solid $color-border;
    margin-bottom: 0;
}
