/*------------------------------------*
    #PAGINATION
 *------------------------------------*/
.pagination {
    @extend .list-inline;
}

.pagination li {
    vertical-align: middle;
}

.pagination__disabled {
    color: #cacaca;
    cursor: not-allowed;
}

.pagination a {
    color: #000;
    @include font-size(14px, 1.4);
    font-family: $font-secondary;
    display: block;
    padding: 9.5px 15px;
    border: 2px solid $color-border;

    &:hover {
        border-color: $color-border-highlight;
    }
}

.pagination__next,
.pagination__prev {

    a {
        padding: 0;
        border: none;
        @include font-size(24px, 1.2);
        @include attention() {
            color: $color-links-hover;
        }
    }

    i:nth-child(3) {
        margin-left: -21px;
        line-height: 42px;
    }
}

