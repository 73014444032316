/*------------------------------------*
    #OVERLAY
 *------------------------------------*/
.overlay {
    display: none;

    @include media-query(lap-and-up) {
        display: block;
        position: fixed;
        z-index: 3;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity 0.3s, visibility 0.3s;
        -moz-transition: opacity 0.3s, visibility 0.3s;
        transition: opacity 0.3s, visibility 0.3s;
    }
}
