/*------------------------------------*
    #NEWSLETTER
 *------------------------------------*/
.newsletter {
    background: $color-brand;
    padding: 20px 0;

    @include media-query(desk) {
        padding: 97px 0;
        background-image: url('../images/subscribe/1920x274/subscribe-bg.jpg');
        background-position: center;
    }

}

.newsletter__heading {
    display: inline-block;
    vertical-align: middle;
    font-family: $font-secondary;
    @include font-size(40px, 1);
    color: $color-newsletter-heading;

    @include media-query(desk) {
        margin-bottom: 0;
    }

}

.newsletter__form {
    display: inline-block;
    vertical-align: middle;
}

.newsletter__email {
    min-width: 395px;
    height: 54px;
    padding: 10px;
    border: none;
    vertical-align: middle;

    @include media-query(palm) {
        margin-bottom: 23px;
        width: 100%;
        min-width: initial;
    }

}

.newsletter__btn {
    height: 54px;
    border-width: 1px;
    vertical-align: middle;
    font-family: $font-secondary;
    font-size: 15px;

    @include media-query(lap-and-up) {
        margin-left: 5px;
    }

}
