/*------------------------------------*
    #QUANTITY
 *------------------------------------*/
.quantity {
    display: inline-block;
    width: 120px;
    height: 42px;
    position: relative;
}

.quantity__btn {
    border: none;
    color: #222;
    background: transparent;
    cursor: pointer;
    display: inline-block;
    height: 42px;
    font-size: 16px;
    line-height: 46px;
    overflow: visible;
    outline: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 35px;
}

.quantity__btn--plus {
    right: 0;
    top: 0;
}

.quantity__qty {
    padding: 0 39px;
    text-align: center;
    width: 120px;
    height: 42px;
    background: #FAFAFA;
}
