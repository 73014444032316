/*------------------------------------*
    #WORK PROCESS
 *------------------------------------*/
.work-process {

}

.work-process__numbers {
    @include font-size(48px, 1.2);
    margin-bottom: 15px;
}

.work-process__name {
    @include font-size(18px, 1.8);
    margin-bottom: 10px;
}

.work-process__description {

}
