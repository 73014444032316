/*------------------------------------*
    #SHOW-HIDE
 *------------------------------------*/

/* Hide visually but not from screen readers */
// Allow important defs in utility class
@mixin hide-text() {
    position: absolute !important;
    height: 1px; width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

.hide-text { @include hide-text(); }

@include media-query(palm) {
    .hide-text-palm { @include hide-text(); }
}

@include media-query(lap) {
    .hide-text-lap { @include hide-text(); }
}

@include media-query(lap-and-up) {
    .hide-text-lap-and-up { @include hide-text(); }
}

@include media-query(portable) {
    .hide-text-portable { @include hide-text(); }
}

@include media-query(desk) {
    .hide-text-desk { @include hide-text(); }
}

@mixin hide() {
    display: none;
}

.hide { @include hide(); }

@include media-query(palm) {
    .hide-palm { @include hide(); }
}

@include media-query(lap) {
    .hide-lap { @include hide(); }
}

@include media-query(lap-and-up) {
    .hide-lap-and-up { @include hide(); }
}

@include media-query(portable) {
    .hide-portable { @include hide(); }
}

@include media-query(desk) {
    .hide-desk { @include hide(); }
}
