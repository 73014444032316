/*------------------------------------*
    #PAGE FOOT
 *------------------------------------*/
.page-foot {
    @include font-size(15px, 24px);
}

    .page-foot__col {
        margin-bottom: 20px;

        @include media-query(lap-and-up) {
            margin-bottom: 0;
        }

    }

        .page-foot__heading {

            margin-bottom: 0;

            @include media-query(desk) {
                margin-bottom: 28px;
            }

        }
