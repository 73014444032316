/*------------------------------------*
    #TABS
 *------------------------------------*/
.vertical-tab {

}

    .vertical-tab__link {
        padding: 14px 0;
        border-bottom: 1px solid $color-border;
        color: $color-tab-item;
        @include attention(false) {
            color: $color-tab-item-hover;
        }
        font-family: $font-secondary;
    }

        .vertical-tab__item-icon {
            @include font-size(11px, 18px);
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -9px;
        }

.vertical-tab__content {
        display: none;
    }

    .vertical-tab__content--visible {
        display: block;
    }
