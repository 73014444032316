/*------------------------------------*
    #Accordion
 *------------------------------------*/

.accordion {
    text-align: center;
}

.accordion__item {
    background: #FAFAFA;
}

.accordion__item + .accordion__item {
    margin-top: 10px;
}

.accordion__mark {
    display: inline-block;
    width: 15px;
    height: 15px;
}

.accordion__title {
    display: inline-block;
    @include font-size(13px, 40px);
    font-family: $font-secondary;
    margin-bottom: 0;
}

.accordion__content {
    text-align: left;
    background: #fff;
    height: 0;
    overflow: hidden;
    transition: all .4s;
    -webkit-transition: all .4s;

    > :last-child {
        margin-bottom: 0;
    }

}

.accordion__mark:checked ~ .accordion__content {
    height: auto;
    padding: 20px 0;
}
