/*------------------------------------*
    #PRODUCT DETAILS
 *------------------------------------*/
.product-details__heading {
    @extend .portfolio-details__heading;
    margin-bottom: 15px;
}

.product-details__reviews {
    margin-bottom: 15px;
}

.product-details__reviews-text {
    margin-right: 10px;
    @include font-size(15px, 1.4);
    vertical-align: top;
    display: inline-block;
}

.product-details__reviews-rating {
    display: inline-block;
    vertical-align: top;
}

.product-details__price {
    @include font-size(36px, 1.2);
    margin-bottom: 25px;
}

.product-details__description {
    @include font-size(15px, 1.8);
    margin-bottom: 40px;
}

.product-details__description {
    strong {
        color: $color-headings;
        font-weight: normal;
    }
}

.product-details__quantity {
    margin-right: 5px;
    margin-bottom: 30px;
}

.product-details__list {
    @extend .portfolio-details__list;
    border-top-width: 0;
    margin-bottom: 40px;
    > li {
        padding: 8px 0;
    }
}

.product-details__btn {
    display: block;
    margin-bottom: 13px;
    @include font-size(15px, 1.2);
    font-family: $font-primary;
}

.product-details__btn-icon {
    width: 46px;
    height: 46px;
    line-height: 46px;
    border-radius: 50%;
    background: #FAFAFA;
    margin-right: 10px;
}
