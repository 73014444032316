/*------------------------------------*
    #FEATURED CATEGORIES
 *------------------------------------*/
.featured-categories {
    position: relative;
    overflow: hidden;
}

    .featured-categories__item {
        margin: 0;
        position: relative;
        @include image-overlay(.8, $color-overlay-secondary);

        @include media-query(lap-and-up) {
            @include image-overlay(0, $color-overlay-secondary);
            @include image-overlay-hover(0.8);
        }

    }

        .featured-categories__content {
            @extend %absolute-centered;
            z-index: 3;
            width: auto;
            min-width: 100%;
            text-align: center;

        }

            .featured-categories__title-main {
                display: inline-block;
                position: relative;
                padding: 18.5px;
                margin-bottom: 12px;
                font-family: $font-secondary;
                letter-spacing: 0.1em;
                @include font-size(20px, 1);
                color: $color-featured-categories-title-hover;

                    &:before,
                    &:after {
                        height: 100%;
                        width: 37px;
                        position: absolute;
                        content: '';
                        transition: all 0.35s ease;
                        opacity: 1;
                    }

                    &:before {
                        right: 0;
                        top: 0;
                        border-right: 2px solid $color-border-highlight;
                        border-top: 2px solid $color-border-highlight;
                    }

                    &:after {
                        left: 0;
                        bottom: 0;
                        border-left: 2px solid $color-border-highlight;
                        border-bottom: 2px solid $color-border-highlight;
                    }

                @include media-query(lap-and-up) {
                    color: $color-featured-categories-title;

                        .featured-categories__item:hover & {
                            color: $color-featured-categories-title-hover;
                            &:before,
                            &:after {
                                width: 100%;
                            }
                        }

                }

                @include media-query(desk) {
                    padding: 28.5px 48px;
                }

            }

            .featured-categories__title-sub {
                margin-bottom: 12px;

                @include media-query(lap-and-up) {
                    opacity: 0;
                    visibility: hidden;
                    -webkit-transition: all 0.4s ease;
                    transition: all 0.4s ease;
                    transform: translateY(-100%);
                    -webkit-transform: translateY(-100%);
                    .featured-categories__item:hover & {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                        -webkit-transform: translateY(0);
                    }
                }

            }

            .featured-categories__link {
                display: block;

                @include media-query(lap-and-up) {
                    opacity: 0;
                    visibility: hidden;
                    -webkit-transition: all 0.4s ease;
                    transition: all 0.4s ease;
                    transform: translateY(100%);
                    -webkit-transform: translateY(100%);
                    .featured-categories__item:hover & {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                        -webkit-transform: translateY(0);
                    }
                }

            }

                .featured-categories__link-text {
                    color: $color-base-text-color;
                }

        // Outer Border
        .featured-categories__border {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 2;
            &:before,
            &:after,
            div:before,
            div:after {
                background-color: $color-border-highlight;
                position: absolute;
                content: "";
                display: block;
                -webkit-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
            }

            &:before,
            &:after {
                width: 0;
                height: 2px;
            }

            div:before,
            div:after {
                width: 2px;
                height: 0;
            }

            &:before,
            div:before {
                left: 0;
                top: 0;
            }
            &:after,
            div:after {
                bottom: 0;
                right: 0;
            }



            .featured-categories__item:hover & {
                &:before,
                &:after {
                    width: 100%;
                }
                div:before,
                div:after {
                    height: 100%;
                }
            }
        }
